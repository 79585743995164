import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

class AddGameModal extends React.Component {
  state = {
    name: ''
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    axios({
      url: apiUrl + '/add-game',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        name: this.state.name
      }
    })
    .then(this.setState({ name: '' }))
    .then(() => this.props.closeAddGameModal())
    .then(() => this.props.getGames())
    .catch((err) => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  render () {
    return (
      <Modal show={this.props.addGameModal} onHide={() => { this.props.closeAddGameModal() ; this.setState({name: '' })}}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Game Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex justify-content-end">
              <Button type='submit' variant='primary'>Submit</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
}

export default AddGameModal