import React from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from 'react-router-dom'
import AddClientModal from '../Clients/AddClientModal'
import AddGameModal from '../Games/AddGameModal'


class Header extends React.Component {
  state = {}

  render () {
    return (
      <React.Fragment>
        <Navbar expand='md' className='main-nav' bg='dark' variant='dark'>
          <Container>
            <Navbar.Brand style={{ cursor: 'pointer' }} onClick={this.props.toMain}>Super Admin Panel</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse>
              <Nav style={{ width: '70%' }}>
                <Link className='nav-link' to='' onClick={(event) => this.props.signOut(event)}>Sign Out</Link>
                <Link className='nav-link' to='' onClick={this.props.showPasswordModal}>Change Password</Link>
                <Link className='nav-link' to='' onClick={this.props.toClients}>Clients</Link>
                <Link className='nav-link' to='' onClick={this.props.toGames}>Games</Link>
                <Link className='nav-link' to='' onClick={this.props.toTerms}>Terms</Link>
              </Nav>
              <Nav className='d-flex justify-content-between'>
                <Link className='nav-link' to='' onClick={this.props.showAddClientModal}>Add Client</Link>
                <Link className='nav-link' to='' onClick={this.props.showAddGameModal}>Add Game</Link>
              </Nav>  
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <AddClientModal {...this.props}></AddClientModal>
        <AddGameModal {...this.props}></AddGameModal>
      </React.Fragment>
    )
  }
}

export default Header