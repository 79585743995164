import React, { useState } from 'react';
import axios from 'axios';
import apiUrl from '../../../apiConfig';
import { Modal, Form, Row, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';

const RegistrationModal = (props) => {
  const [formData, setFormData] = useState({
    countryStates: ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming'],
    educationLevel: ['No High School', 'Freshman', 'Sophomore', 'Junior', 'Senior', 'College'],
    firstName: '',
    lastName: '',
    email: props.currentEmail,
    mobile: '',
    birthday: '',
    school: '',
    company: '',
    education: '',
    state: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    agree: 'yes'
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    axios({
      url: apiUrl + `/registrants`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
        },
      data: {
        email: props.currentEmail,
        first_name: formData.firstName,
        last_name: formData.lastName,
        high_school: formData.school,
        company_name: formData.company,
        mobile_phone: formData.mobile,
        birthday: formData.birthday,
        education: formData.education,
        address_1: formData.address1,
        address_2: formData.address2,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        contact: formData.agree,
        quiz_id: props.quiz[0].id,
        timestamp: Date.parse(new Date())
        }
    })
      .then(() => {
        props.closeRegistration()
        props.setPlayer(formData)
        props.setAuthenticated(true)
        props.msgAlert({
          heading: 'Registration Complete',
          message: 'You are now registered to play. Good luck!',
          variant: 'success'
        })
      },
        setFormData({
            countryStates: ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia','Wisconsin','Wyoming'],
            educationLevel: ['No High School', 'Freshman', 'Sophomore', 'Junior', 'Senior', 'College'],
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            birthday: '',
            school: '',
            company: '',
            education: '',
            state: '',
            address1: '',
            address2: '',
            city: '',
            zip: '',
            agree: 'yes'
        })
      )
      .catch((err) => {
        props.msgAlert({
          heading: 'Server Error',
          message: err.response.data.error,
          variant: 'danger'
        })
      })
  }

  return (
    <React.Fragment>
      <Modal show={props.needToRegister} onHide={props.closeRegistration}>
        <Modal.Header closeButton>
          <Modal.Title>Register to Play</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className='mt-2 mb-2 d-flex row-cols-2'>
              {props.quiz[0].registration_first_name === 1 &&
              <Form.Group className='mt-1'>
                <Form.Control size='sm' required name='firstName' value={formData.firstName} onChange={handleChange} placeholder='First Name*' type='text' />
              </Form.Group>
              }
              {props.quiz[0].registration_last_name === 1 &&
              <Form.Group className='mt-1'>
                <Form.Control size='sm' required name='lastName' value={formData.lastName} onChange={handleChange} placeholder='Last Name*' type='text'/>
              </Form.Group>
              }
              <Form.Group className='mt-1'>
                <Form.Control style={{ backgroundColor: 'white' }} disabled size='sm' required name='email' defaultValue={props.currentEmail} value={props.currentEmail} onChange={handleChange} placeholder='Email*' type='email'/>
              </Form.Group>
              {props.quiz[0].registration_mobile_phone === 1 &&
              <Form.Group className='mt-1'>
                <InputMask mask='(999) 999-9999' size='sm' name='mobile' value={formData.mobile} onChange={handleChange} placeholder='Mobile Phone'>
                  {(inputProps) => <Form.Control required {...inputProps} type='tel'/>}
                </InputMask>
              </Form.Group>
              }
              {props.quiz[0].registration_birthday === 1 &&
              <Form.Group className='mt-1'>
                <InputMask mask='99/99/9999' size='sm' name='birthday' value={formData.birthday} onChange={handleChange} placeholder='Birth Date*'>
                  {(inputProps) => <Form.Control required {...inputProps} type='text'/>}
                </InputMask>
              </Form.Group>
              }
              {props.quiz[0].registration_company_name === 1 &&
              <Form.Group className='mt-1'>
                <Form.Control size='sm' name='company' value={formData.company} onChange={handleChange} placeholder='Company Name*' type='text'/>
              </Form.Group>
              }
              {props.quiz[0].registration_high_school === 1 &&
              <Form.Group className='mt-1'>
                <Form.Control required size='sm' name='school' value={formData.school} onChange={handleChange} placeholder='School Name*' type='text'/>
              </Form.Group>
              }
              {props.quiz[0].registration_education === 1 &&
              <Form.Group className='mt-1'>
                <Form.Select required  size='sm' name='education' value={formData.education} onChange={handleChange} placeholder='State*' type='text'>
                      <option value=''>Education Level*</option>
                    {formData.educationLevel.map((s) => (
                      <option value={s} key={s}>{s}</option>
                    ))}
                  </Form.Select>
              </Form.Group>
              }
              {props.quiz[0].registration_state === 1 &&
              <Form.Group className='mt-1'>
                <Form.Select required  size='sm' name='state' value={formData.state} onChange={handleChange} placeholder='State*' type='text'>
                    <option value=''>State*</option>
                  {formData.countryStates.map((s) => (
                    <option value={s} key={s}>{s}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              }
              {props.quiz[0].registration_address_1 === 1 &&
              <Form.Group className='mt-1'>
                <Form.Control size='sm' required name='address1' value={formData.address1} onChange={handleChange} placeholder='Street Address*' type='text'/>
              </Form.Group>
              }
              {props.quiz[0].registration_address_2 === 1 &&
              <Form.Group className='mt-1'>
                <Form.Control size='sm' name='address2' value={formData.address2} onChange={handleChange} placeholder='Address Apt/Suite #' type='text'/>
              </Form.Group>
              }
              {props.quiz[0].registration_city === 1 &&
              <Form.Group className='mt-1'>
                <Form.Control size='sm' required name='city' value={formData.city} onChange={handleChange} placeholder='City*' type='text'/>
              </Form.Group>
              }
              {props.quiz[0].registration_zip === 1 &&
              <Form.Group className='mt-1'>
                <Form.Control size='sm' required name='zip' value={formData.zip} onChange={handleChange} placeholder='Zip Code*' type='number'/>
              </Form.Group>
              }
            </Row>
            <Row className='mt-2 mb-2'>
              <Form.Group className='col-12'>
                <Form.Label style={{ padding: '1rem' }}>I agree to be contacted by QuizBee or its sponsors &#40;select yes or no&#41; *</Form.Label>
                <Form.Select size='sm' name='agree' onChange={handleChange} required>
                  <option value='yes'>YES</option>
                  <option value='no'>NO</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className='mt-2 mb-2'>
              <Form.Group className='d-flex justify-content-end'>
                <Button size='sm' type='submit' variant='dark'>Register</Button>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default RegistrationModal;