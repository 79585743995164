import { React, useState, useEffect } from 'react';
import PreGame from './PreGame';
import PostGame from './PostGame';
import { Container } from 'react-bootstrap';
import TriviaGameTemplate from '../TriviaGameTemplate/TriviaGameTemplate';
import { useTimer } from './TimerProvider';

const Game = ({ questions, quiz, player, msgAlert }) => {
    const [preGame, setPreGame] = useState(true);
    const [game, setGame] = useState(false);
    const [postGame, setPostGame] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [incorrect, setIncorrect] = useState(0);
    const [shuffledOptions, setShuffledOptions] = useState([]);

    const { startTimer, resetTimer } = useTimer();

    useEffect(() => {
      shuffleOptions(questions[currentQuestionIndex]);
  }, [currentQuestionIndex, questions]);

  useEffect(() => {
      if (questions[currentQuestionIndex]) {
          shuffleOptions(questions[currentQuestionIndex]);
      }
  }, [questions, currentQuestionIndex]);
    

    const gameInPlay = () => {
        setPreGame(false);
        setGame(true);
        startTimer();
    }
    const gameEnded = () => {
        setGame(false);
        setPostGame(true);
    }
    const playAgain = () => {
        resetTimer();
        setPreGame(true);
        setPostGame(false);
        setCurrentQuestionIndex(0);
        setScore(0);
        setIncorrect(0);
    }

    const handleOptionSelect = (isCorrect) => {
        if (isCorrect) {
          setScore(score + 1);
        } else {
          setIncorrect(incorrect + 1);
        }
        const nextQuestionIndex = currentQuestionIndex + 1;
        if (nextQuestionIndex < questions.length) {
          setCurrentQuestionIndex(nextQuestionIndex);
        } else {
          gameEnded();
        }
      };

    const currentQuestion = questions[currentQuestionIndex];

    const shuffleOptions = (question) => {
      if (!question) return; // Handle case when question is not yet available
      let options = [...question.options]; // Create a new array to avoid mutating the original
      for (let i = options.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [options[i], options[j]] = [options[j], options[i]];
      }
      setShuffledOptions(options);
  }

    return (
        <Container fluid className='d-flex flex-grow-1'>
            {preGame && <PreGame gameInPlay={gameInPlay} msgAlert={msgAlert} quiz={quiz} player={player}/> }
            {postGame && <PostGame score={score} incorrect={incorrect} quiz={quiz} questions={questions} player={player} playAgain={playAgain} msgAlert={msgAlert}/>}
            {game &&
                <>
                    <TriviaGameTemplate quiz={quiz} question={{...currentQuestion, options: shuffledOptions}} handleOptionSelect={handleOptionSelect} score={score} gameEnded={gameEnded} />
                </>
            }
        </Container>
    )
}

export default Game;