import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import { Button, Container, Spinner } from 'react-bootstrap' 
import { CSVLink } from 'react-csv'
import GetAppIcon from '@mui/icons-material/GetApp'
import { calculateScore } from '../../../../TriviaGamePlay/HelperFunctions/calculateScore'

class Reports extends React.Component {
  state = {
    quizRegistrants: [],
    gettingQuizRegistrants: false,
    gotQuizRegistrants: false,
    quizPlays: [],
    gettingQuizPlays: false,
    gotQuizPlays: false,
    gameRegistrants: [],
    gettingGameRegistrants: false,
    gotGameRegistrants: false,
    gamePlays: [],
    gettingGamePlays: false,
    gotGamePlays: false,
  }

  getQuizRegistrants = () => {
    this.setState({ gettingQuizRegistrants: true, gotQuizRegistrants: false })
    axios({
      url: apiUrl + '/get-quiz-registrants',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        quizId: this.props.quizData.id
      }
    })
    .then((res) => this.setQuizRegTimestamp(res.data.registrants))
    .catch((err) => {
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    })
  }

  setQuizRegTimestamp = (array) => {
    const newArr = array.map((obj) => {
      return {...obj, timestamp: new Date(parseInt(obj.timestamp)).toLocaleString()} 
    })
    this.setState({ quizRegistrants: newArr, gettingQuizRegistrants: false, gotQuizRegistrants: true })
  }

  getQuizPlays = () => {
    this.setState({ gettingQuizPlays: true, gotQuizPlays: false })
    axios({
      url: apiUrl + '/get-quiz-plays',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        quizId: this.props.quizData.id
      }
    })
    .then((res) => this.setQuizPlayTimestamp(res.data.plays))
    .catch((err) => {
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    })
  }

  setQuizPlayTimestamp = (array) => {
    const newArr = array.map((obj) => {
      return {...obj, timestamp: new Date(parseInt(obj.timestamp)).toLocaleString(), score: calculateScore(obj.quiz_duration, obj.total_correct, obj.total_incorrect)} 
    })
    this.setState({ quizPlays: newArr, gettingQuizPlays: false, gotQuizPlays: true })
  }

  getGameRegistrants = () => {
    this.setState({ gettingGameRegistrants: true, gotGameRegistrants: false })
    axios({
      url: apiUrl + '/get-game-registrants',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        ugId: this.props.quizData.usergame_id
      }
    })
    .then((res) => this.setGameRegTimestamp(res.data.registrants))
    .catch((err) => {
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    })
  }

  setGameRegTimestamp = (array) => {
    const newArr = array.map((obj) => {
      return {...obj, timestamp: new Date(parseInt(obj.timestamp)).toLocaleString()} 
    })
    this.setState({ gameRegistrants: newArr, gettingGameRegistrants: false, gotGameRegistrants: true })
  }

  getGamePlays = () => {
    this.setState({ gettingGamePlays: true, gotGamePlays: false })
    axios({
      url: apiUrl + '/get-game-plays',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        ugId: this.props.quizData.usergame_id
      }
    })
    .then((res) => this.setGamePlayTimestamp(res.data.plays))
    .catch((err) => {
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    })
  }

  setGamePlayTimestamp = (array) => {
    const newArr = array.map((obj) => {
      return {...obj, timestamp: new Date(parseInt(obj.timestamp)).toLocaleString(), score: calculateScore(obj.quiz_duration, obj.total_correct, obj.total_incorrect)} 
    })
    this.setState({ gamePlays: newArr, gettingGamePlays: false, gotGamePlays: true })
  }


  render () {
    return (
      <React.Fragment>
        <Container className='d-flex justify-content-evenly mt-2 mb-4'>
          <div>
            {!this.state.gettingQuizRegistrants && !this.state.gotQuizRegistrants &&
            <Button variant='outline-dark' onClick={this.getQuizRegistrants}>Quiz Registrants</Button>
            }
            {this.state.gettingQuizRegistrants &&
            <Button variant='outline-dark'><Spinner as='span' animation='grow' size='sm' role='status' /> Generating...</Button>
            }
            {this.state.gotQuizRegistrants &&
            <CSVLink filename='quiz_registrants' data={this.state.quizRegistrants} headers={[ {label: 'quiz_title', key: 'quiz_title'}, {label: 'game', key: 'game'}, {label: 'email', key: 'email'},{label: 'first_name', key: 'first_name'},{label: 'last_name', key: 'last_name'},{label: 'high_school', key: 'high_school'},{label: 'company_name', key: 'company_name'},{label: 'mobile_phone', key: 'mobile_phone'},{label: 'birthday', key: 'birthday'},{label: 'education', key: 'education'},{label: 'address_1', key: 'address_1'},{label: 'address_2', key: 'address_2'},{label: 'city', key: 'city'}, {label: 'state', key: 'state'}, {label: 'zip', key: 'zip'}, {label: 'contact', key: 'contact'}, {label: 'timestamp', key: 'timestamp'} ]} className='btn' size='sm' style={{ color: 'white', backgroundColor: '#008080' }}>Quiz Registrants <GetAppIcon fontSize="small" sx={{ color: 'white' }}></GetAppIcon></CSVLink>
            }
          </div>
          <div>
            {!this.state.gettingQuizPlays && !this.state.gotQuizPlays &&
            <Button variant='outline-dark' onClick={this.getQuizPlays}>Quiz Plays</Button>
            }
            {this.state.gettingQuizPlays &&
            <Button variant='outline-dark'><Spinner as='span' animation='grow' size='sm' role='status' /> Generating...</Button>
            }
            {this.state.gotQuizPlays &&
            <CSVLink filename='quiz_plays' data={this.state.quizPlays} headers={[ {label: 'quiz_title', key: 'quiz_title'}, {label: 'game', key: 'game'}, {label: 'total_correct', key: 'total_correct'}, {label: 'total_incorrect', key: 'total_incorrect'}, {label: 'total_questions', key: 'total_questions'}, {label: 'score', key: 'score'}, {label: 'email', key: 'email'},{label: 'first_name', key: 'first_name'},{label: 'last_name', key: 'last_name'},{label: 'high_school', key: 'high_school'}, {label: 'company_name', key: 'company_name'}, {label: 'mobile_phone', key: 'mobile_phone'}, {label: 'birthday', key: 'birthday'}, {label: 'education', key: 'education'}, {label: 'address_1', key: 'address_1'}, {label: 'address_2', key: 'address_2'}, {label: 'city', key: 'city'}, {label: 'state', key: 'state'}, {label: 'zip', key: 'zip'}, {label: 'timestamp', key: 'timestamp'} ]} className='btn' size='sm' style={{ color: 'white', backgroundColor: '#008080' }}>Quiz Plays <GetAppIcon fontSize="small" sx={{ color: 'white' }}></GetAppIcon></CSVLink>
            }
          </div>
          <div>
            {!this.state.gettingGameRegistrants && !this.state.gotGameRegistrants &&
            <Button variant='outline-dark' onClick={this.getGameRegistrants}>Game Registrants</Button>
            }
            {this.state.gettingGameRegistrants &&
            <Button variant='outline-dark'><Spinner as='span' animation='grow' size='sm' role='status' /> Generating...</Button>
            }
            {this.state.gotGameRegistrants &&
            <CSVLink filename='game_registrants' data={this.state.gameRegistrants} headers={[ {label: 'quiz_title', key: 'quiz_title'}, {label: 'game', key: 'game'}, {label: 'email', key: 'email'},{label: 'first_name', key: 'first_name'},{label: 'last_name', key: 'last_name'},{label: 'high_school', key: 'high_school'},{label: 'company_name', key: 'company_name'},{label: 'mobile_phone', key: 'mobile_phone'},{label: 'birthday', key: 'birthday'},{label: 'education', key: 'education'},{label: 'address_1', key: 'address_1'},{label: 'address_2', key: 'address_2'},{label: 'city', key: 'city'}, {label: 'state', key: 'state'}, {label: 'zip', key: 'zip'}, {label: 'contact', key: 'contact'}, {label: 'timestamp', key: 'timestamp'} ]} className='btn' size='sm' style={{ color: 'white', backgroundColor: '#008080' }}>Game Registrants <GetAppIcon fontSize="small" sx={{ color: 'white' }}></GetAppIcon></CSVLink>
            }
          </div>
          <div>
            {!this.state.gettingGamePlays && !this.state.gotGamePlays &&
            <Button variant='outline-dark' onClick={this.getGamePlays}>Game Plays</Button>
            }
            {this.state.gettingGamePlays &&
            <Button variant='outline-dark'><Spinner as='span' animation='grow' size='sm' role='status' /> Generating...</Button>
            }
            {this.state.gotGamePlays &&
            <CSVLink filename='game_plays' data={this.state.gamePlays} headers={[ {label: 'quiz_title', key: 'quiz_title'}, {label: 'game', key: 'game'}, {label: 'total_correct', key: 'total_correct'}, {label: 'total_incorrect', key: 'total_incorrect'}, {label: 'total_questions', key: 'total_questions'}, {label: 'score', key: 'score'}, {label: 'email', key: 'email'},{label: 'first_name', key: 'first_name'},{label: 'last_name', key: 'last_name'},{label: 'high_school', key: 'high_school'},{label: 'company_name', key: 'company_name'}, {label: 'mobile_phone', key: 'mobile_phone'}, {label: 'birthday', key: 'birthday'}, {label: 'education', key: 'education'}, {label: 'address_1', key: 'address_1'}, {label: 'address_2', key: 'address_2'}, {label: 'city', key: 'city'}, {label: 'state', key: 'state'}, {label: 'zip', key: 'zip'}, {label: 'timestamp', key: 'timestamp'} ]} className='btn' size='sm' style={{ color: 'white', backgroundColor: '#008080' }}>Game Plays <GetAppIcon fontSize="small" sx={{ color: 'white' }}></GetAppIcon></CSVLink>
            }
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

export default Reports