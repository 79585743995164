import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import ClientsTable from './ClientsTable'
import AddClientModal from './AddClientModal'
import Button from 'react-bootstrap/Button'

class Clients extends React.Component {
  state = {
    users: [],
    userDatabase: [],
    usersCognito: []
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/users',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    // .then(res => this.setState({ users: res.data.data.Users }))
    .then(res => this.setState({ usersCognito: res.data.data.Users},
      () => this.getDUsers()
      ))
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  getDUsers = () => {
    axios({
      url: apiUrl + '/d-users',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    .then((res) => this.setState({ userDatabase: res.data.users },
      () => this.removeSAdmins()
      ))
  }

  removeSAdmins = () => {
    const newArray = []
    this.state.usersCognito.forEach((a,i) => {
      this.state.userDatabase.forEach((us, id) => {
        if (a.Attributes[4].Value === us.email && us.super === 0) {
          newArray.push(a)
        }
      })
    })
    this.setState({ users: newArray })
  }

  getClients = () => {
    axios({
      url: apiUrl + '/users',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    .then(res => this.setState({ usersCognito: res.data.data.Users},
      () => this.getDUsers()
      ))
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  // removeSuperAdmins = (userArray) => {
  //   let newArray = []
  //   userArray.forEach((a) => {
  //     if (a.Attributes[4].Value !== 'akeemogle@gmail.com' && a.Attributes[4].Value !== 'aogle@hcmpllc.com') {
  //       newArray.push(a)
  //     }
  //   })
  //   this.setState({ users: newArray, loaded: true })
  // }

  render () {
    return (
      <React.Fragment>
        <div className='d-flex'>
        {this.props.userData[0].firstName}&apos;s Clients
        <Button variant='primary' style={{ marginLeft: '1rem' }} onClick={this.props.showAddClientModal}>Add Client</Button>
        </div>
        <ClientsTable {...this.state} {...this.props} getClients={this.getClients}></ClientsTable>
        <AddClientModal {...this.props} getClients={this.getClients}></AddClientModal>
      </React.Fragment>
    )
  }
}

export default Clients