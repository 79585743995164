import { React, useState } from 'react'
import { Row, Form, Button } from 'react-bootstrap'
import axios from 'axios'
import apiUrl from '../../../apiConfig'

const Registration = (props) => {
    const [email, setEmail] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()
        // check if email is already registered
        axios({
            url: `${apiUrl}/registrant/${email}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
            }
        })
            .then((res) => {
                if (res.data.player.length === 0) {
                    // if not, register
                    props.msgAlert({
                        heading: 'Registration Needed',
                        message: `Let's get you registered to play!`,
                        variant: 'danger'
                    })
                    props.promptRegistration(email)
                } else {
                    props.msgAlert({
                        heading: 'Welcome Back!',
                        message: 'You are already registered to play. Good luck!',
                        variant: 'success'
                    })
                    props.setPlayer(res.data.player[0])
                    props.setAuthenticated(true)
                    setEmail('')
                }
            })
            .catch(() => {
                props.msgAlert({
                    heading: 'Server Error',
                    message: 'Please try again later.',
                    variant: 'danger'
                })
            })
        
    }

    return (
        <Row className='' style={{ color: 'white' }}>
            <h4 className='text-center'>Register to Play</h4>
            <Form onSubmit={handleSubmit} className='mt-2'>
                <Form.Group className='d-flex'>
                    <Form.Control
                    required
                        style={{ backgroundColor: 'transparent', outline: 'none', borderRadius: '0', color: 'white' }}
                        className="custom-placeholder-color"
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button style={{ borderRadius: '0' }} variant="light" type="submit" size='sm'>Start</Button>
                </Form.Group>
            </Form>
        </Row>
    )
}

export default Registration