import React from 'react'
import SponsorMain from './SponsorMain'

const SponsorVideo = (props) => {

  const convertLink = (regLink) => {
    if (regLink.includes('=')) {
      const split = regLink.split('=')
      const embedLink = `https://www.youtube.com/embed/${split[1]}?autoplay=1`
      return embedLink
    } else if (regLink.includes('embed')) {
      return regLink + '?autoplay=1'
    } else {
      const split = regLink.split('/')
      const embedLink = `https://www.youtube.com/embed/${split[3]}?autoplay=1`
      return embedLink
    }
  }

    return (
      <div className='d-flex justify-content-center mt-3'>
        {props.quiz[0].sponsor_video_id &&
          <iframe width="560" height="315" src={convertLink(props.quiz[0].sponsor_video_link)} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        }
        {!props.quiz[0].sponsor_video_id &&
          <SponsorMain {...props} />
        }
      </div>
    )
}

export default SponsorVideo