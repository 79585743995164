import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

class AddClientModal extends React.Component {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    company: '',
    password: ''
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    axios({
      url: apiUrl + '/sign-up',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        company: this.state.company,
        password: this.state.password
      }
    })
    .then(this.setState({ email: '', firstName: '', lastName: '', company: '', password: '' }))
    .then(() => this.props.closeAddClientModal())
    .then(() => this.props.getClients())
    .catch((err) => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  render () {
    return (
      <Modal show={this.props.addClientModal} onHide={() => { this.props.closeAddClientModal(); this.setState({ email: '', firstName: '', lastName: '', company: '', password: '' }) }}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                autoFocus
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="company"
                value={this.state.company}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Temporary Password &nbsp;<span style={{ fontSize: '.7rem', fontWeight: 'bold'}}>*&nbsp;min 8 chars. 1 uppercase, 1 lowercase</span></Form.Label>
              <Form.Control
                type="password"
                placeholder=""
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex justify-content-end">
              <Button type='submit' variant='primary'>Submit</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
}

export default AddClientModal