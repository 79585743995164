import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import AddSponsorModal from './AddSponsorModal'
import SponsorsTable from './SponsorsTable'
import Button from 'react-bootstrap/Button'

class Sponsors extends React.Component {
  state = {
    theSponsors: []
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/get-sponsors',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    .then(res => this.setState({ theSponsors: res.data.sponsors }))
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  getSponsors = () => {
    axios({
      url: apiUrl + '/get-sponsors',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    .then(res => this.setState({ theSponsors: res.data.sponsors }))
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  render () {
    return (
      <React.Fragment>
        <div className='d-flex'>
        Your Sponsors
        <Button variant='primary' style={{ marginLeft: '1rem' }} onClick={this.props.showAddSponsorModal}>Add Sponsor</Button>
        </div>
        <SponsorsTable {...this.state} {...this.props} getSponsors={this.getSponsors}></SponsorsTable>
        <AddSponsorModal {...this.props} getSponsors={this.getSponsors}></AddSponsorModal>
      </React.Fragment>
    )
  }
}

export default Sponsors