import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import { CSVLink } from 'react-csv'
import Modal from 'react-bootstrap/Modal'
import { Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'

class EditQuestionsModal extends React.Component {
  state = {
    questions: [],
    selectedFileType: '',
    selectedFile: '',
    loaded: false,
    deleteQuestions: false
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/get-questions',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        usergameId: this.props.selectedUserGameId
      }
    })
      .then(res => this.setState({ questions: res.data.questions, loaded: true }))
  }

  getQuestions = () => {
    axios({
      url: apiUrl + '/get-questions',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        usergameId: this.props.selectedUserGameId
      }
    })
      .then(res => this.setState({ questions: res.data.questions, loaded: true }))
  }

  handleChange = (event) => {
    const file = event.target.files[0]
    const fileType = event.target.files[0].name.split('.')[1]
    this.setState({ selectedFileType: fileType, [event.target.name]: file })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData()
    data.append('file', this.state.selectedFile)
    data.append('usergameId', this.props.selectedUserGameId)
    data.append('usergamename', this.props.selectedUserGameName)
    data.append('cognitoId', this.props.userData[0].cognitoId)
    axios({
      url: apiUrl + '/add-questions',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: data
    })
    .then(() => this.setState({ loaded: false, selectedFile: '', selectedFileType: '', questions: [] },
    () => this.getQuestions()
    ))
    .then(() => {
      this.props.msgAlert({
        heading: 'Success',
        message: 'Successfully Added Questions',
        variant: 'success'
      })
    })
    .catch((err) => {
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      },
      this.props.closeEditQuestionsModal()
      )
      this.setState({ selectedFile: '', selectedFileType: '', questions: [] })
    })
  }

  deleteQuestions = () => {
    axios({
      url: apiUrl + '/delete-questions',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        usergameId: this.props.selectedUserGameId
      }
    })
    .then(() => this.setState({ loaded: false, selectedFile: '', selectedFileType: '', questions: [], deleteQuestions: false },
    () => this.getQuestions()
    ))
    .then(() => {
      this.props.msgAlert({
        heading: 'Questions Successfully Deleted',
        variant: 'success'
      })
    })
    .catch(() => {
      this.props.msgAlert({
        heading: 'Error',
        message: 'Something went wrong, please try again.',
        variant: 'danger'
      })
    })
  } 

  render () {
    const disabled = this.state.selectedFileType !== 'csv' 
    const notValidated = this.state.selectedFileType !== '' && this.state.selectedFileType !== 'csv'
    return (
      <React.Fragment>
        <Modal show={this.props.editQuestionsModal} onHide={() => { this.props.closeEditQuestionsModal(); this.setState({questions: '', selectedFile: '', selectedFileType: ''}) }}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Questions for <span style={{ fontStyle: 'italic' }}>'{this.props.selectedUserGameName}'</span></Modal.Title>
          </Modal.Header>
          {this.state.loaded &&
          <Modal.Body>
            {this.state.questions.length < 1 &&
            <React.Fragment>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Label>Upload a .csv file with your questions</Form.Label>
                <Form.Control required type='file' name='selectedFile' accept='.csv' onChange={this.handleChange}/>
              </Form.Group>
              <Form.Group>
                {notValidated &&
                <span>*** must be a csv file</span>
                }
              </Form.Group>
              <Form.Group className='mt-2 d-flex justify-content-end'>
                <Button type='submit' disabled={disabled} variant='primary'>Submit</Button>
              </Form.Group>
            </Form>
            <div className='mt-5'>Currently there aren&apos;t any questions uploaded for this game. To add questions, download the template below and fill it out. You must include at least one correct answer and one incorrect answer for each question. You can add up to seven incorrect answers. Make sure to include all appropriate punctuation and capitalization. Once you are finished, upload the file.</div>
            <div className='d-flex justify-content-center mt-2'>
              <CSVLink filename='questions.csv' data={[]} headers={['question', 'correct_answer', 'incorrect_answer1', 'incorrect_answer2','incorrect_answer3','incorrect_answer4','incorrect_answer5','incorrect_answer6','incorrect_answer7']} className='btn' style={{ color: 'white', backgroundColor: '#008080' }}>Download Template</CSVLink>
            </div>
            </React.Fragment>
            }
            {this.state.questions.length >= 1 &&
            <div style={{ maxHeight: '600px', overflowY: 'scroll' }}>
              <div className='d-flex justify-content-around'>
                {!this.state.deleteQuestions &&
                <React.Fragment>
                  <CSVLink filename='questions.csv' data={this.state.questions} headers={[ {label: 'question', key: 'question'},{label: 'correct_answer', key: 'correct_answer'},{label: 'incorrect_answer1', key: 'incorrect_answer1'},{label: 'incorrect_answer2', key: 'incorrect_answer2'},{label: 'incorrect_answer3', key: 'incorrect_answer3'},{label: 'incorrect_answer4', key: 'incorrect_answer4'},{label: 'incorrect_answer5', key: 'incorrect_answer5'},{label: 'incorrect_answer6', key: 'incorrect_answer6'},{label: 'incorrect_answer7', key: 'incorrect_answer7'} ]} className='btn' style={{ color: 'white', backgroundColor: '#008080' }}>Download Questions</CSVLink>
                  <Button onClick={() => this.setState({ deleteQuestions: true })}>Delete Questions</Button>
                </React.Fragment>
                }
              </div>
              <div>  
                {this.state.deleteQuestions &&
                <React.Fragment>
                  <p style={{ textAlign: 'center' }}>Are you sure you want to delete these questions? You will have the option to upload new questions after these are deleted.</p>
                  <div className='d-flex justify-content-around'>
                    <Button onClick={() => this.setState({ deleteQuestions: false, selectedFileType: '', selectedFile: '' })}>Cancel</Button>
                    <Button style={{ marginLeft: '10px' }}  variant='danger' onClick={this.deleteQuestions}>Delete</Button>
                  </div>
                </React.Fragment>  
                }
              </div>
              {this.state.questions.map((ques, i) => (
                <div key={ques.id}>
                  <div className='mb-1 mt-3'>{i+1}. {ques.question}</div>
                  <ListGroup>
                    <ListGroup.Item>
                      <span style={{ fontWeight: 'bold' }}>{ques.correct_answer}  &#40;answer&#41;</span>
                    </ListGroup.Item>
                    <ListGroup.Item>{ques.incorrect_answer1}</ListGroup.Item>
                    {ques.incorrect_answer2 ? <ListGroup.Item>{ques.incorrect_answer2}</ListGroup.Item> : <div></div>}
                    {ques.incorrect_answer3 ? <ListGroup.Item>{ques.incorrect_answer3}</ListGroup.Item> : <div></div>}
                    {ques.incorrect_answer4 ? <ListGroup.Item>{ques.incorrect_answer4}</ListGroup.Item> : <div></div>}
                    {ques.incorrect_answer5 ? <ListGroup.Item>{ques.incorrect_answer5}</ListGroup.Item> : <div></div>}
                    {ques.incorrect_answer6 ? <ListGroup.Item>{ques.incorrect_answer6}</ListGroup.Item> : <div></div>}
                    {ques.incorrect_answer7 ? <ListGroup.Item>{ques.incorrect_answer7}</ListGroup.Item> : <div></div>}
                  </ListGroup>
                </div>
              ))}
            </div>
            }
          </Modal.Body>
          }
        </Modal>
      </React.Fragment>
    )
  }
}

export default EditQuestionsModal