import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// import Logo from '../../../images/quizbee_logo_black.png';

//testing

const Brand = ({quiz}) => {
  return (
    <Container>
      <Row>
        <Col className='d-flex justify-content-center pt-5'>
          {quiz[0].sponsor_id &&
            <a href={quiz[0].sponsor_web_link}><img alt='sponsor' src={quiz[0].sponsor_link} style={{ objectFit: 'contain', maxWidth: '150px', height: 'auto' }}/></a>
          }
        </Col>
      </Row>
    </Container>
  );
}

export default Brand;