

export const calculateScore = (duration, correct, incorrect) => {
    const divisor = duration / 60;
    const weightedCorrect = correct / divisor;
    const weightedIncorrect = incorrect / divisor;
    const pointsPerCorrect = 10;
    const pointsPerIncorrect = -5;

    const score = duration === 0 ? 0 : (weightedCorrect * pointsPerCorrect) + (weightedIncorrect * pointsPerIncorrect);

    //console.log('score', score, 'weightedCorrect', weightedCorrect, 'weightedIncorrect', weightedIncorrect, 'divisor', divisor, 'duration', duration, 'correct', correct, 'incorrect', incorrect);

    return score;
}