import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import clientUrl from '../../../../../clientConfig'
import Reports from '../Reports/Reports'
import AddQuizModal from './AddQuizModal'
import { Button, ListGroup, Container, Form, Row, Col } from 'react-bootstrap'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextEditor from './TextEditor'

class Quizzes extends React.Component {
  state = {
    theQuizzes: [],
    theSponsors: []
  }

  formKeysDisplayNames = {
    registration_first_name: 'First Name',
    registration_last_name: 'Last Name',
    registration_high_school: 'School Name',
    registration_company_name: 'Company Name',
    registration_mobile_phone: 'Mobile Phone',
    registration_birthday: 'Birth Date',
    registration_education: 'Education Level',
    registration_address_1: 'Address',
    registration_address_2: 'Address Apt/Suite #',
    registration_city: 'City',
    registration_state: 'State',
    registration_zip: 'Zip Code'
  }

  formKeys = Object.keys(this.formKeysDisplayNames)

  componentDidMount () {
    axios({
      url: apiUrl + '/get-quizzes',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
      .then((res) => {
        this.setState({ theQuizzes: res.data.quizzes })
        this.getSponsors()
      })
      .catch(() => {
        // console.log(error.response.data.error)
        this.props.msgAlert({
          heading: 'Server Error',
          message: 'Please Sign Out and Try Later',
          variant: 'danger'
        })
      }
      )
  }

  getSponsors = () => {
    axios({
      url: apiUrl + '/get-sponsors',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    .then((res) => this.setState({ theSponsors: res.data.sponsors }))
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  getQuizzes = () => {
    axios({
      url: apiUrl + '/get-quizzes',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
      .then((res) => this.setState({ theQuizzes: res.data.quizzes }))
      .catch(() => {
        // console.log(error.response.data.error)
        this.props.msgAlert({
          heading: 'Server Error',
          message: 'Please Sign Out and Try Later',
          variant: 'danger'
        })
      }
      )
  }

  convertTimeStamp = (unix) => {
    const dateObj = new Date(parseInt(unix)).toLocaleDateString('en-US')
    // const utcString = dateObj.toUTCString()
    // return utcString.slice(-11, -4)
    return dateObj
  }

  handleDeleteQuiz = (event) => {
    event.preventDefault()
    axios({
      url: apiUrl + '/delete-quiz',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        id: event.target.dataset.id
      }
    })
      .then(() => {
        this.props.msgAlert({
          heading: 'Successfully Deleted Quiz',
          variant: 'success'
        })
        this.getQuizzes()
      })
      .catch((err) => {
        // console.log(error.response.data.error)
        this.props.msgAlert({
          heading: 'Error',
          message: err.response.data.error,
          variant: 'danger'
        })
      }
      )
  }

  toggleActivation = (boolean, id, index) => {
    const number = boolean === true ? 1 : 0
    const newQuizzes = [...this.state.theQuizzes]
    newQuizzes[index].active = number
    // this.setState({ theQuizzes: newQuizzes})
    axios({
      url: apiUrl + '/edit-quiz',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        id: id,
        active: number
      }
    })
    .then(() => {
      this.props.msgAlert({
        heading: 'Successfully Edited Quiz',
        variant: 'success'
      })
      this.setState({ theQuizzes: newQuizzes})
    })
    .catch((err) => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    )
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleChangeText = (name, value) => {
    this.setState({ [name]: value })
  }

  handleSubmit = (event) => {
    // console.log(event.target.name, this.state[`${event.target.dataset.id}-text_field`], event.target.dataset.id)
    event.preventDefault()
    let v
    if ( event.target.name === 'duration') {
      v = this.state[`${event.target.dataset.id}-duration`]
    } else if ( event.target.name === 'text_field') {
      v = this.state[`${event.target.dataset.id}-text_field`]
    } else if ( event.target.name === 'terms') {
      v = this.state[`${event.target.dataset.id}-terms`]
    } else if (event.target.name === 'sponsor') {
      v = this.state[`${event.target.dataset.id}-sponsor`]
    } else if (event.target.name === 'sponsor_2') {
      v = this.state[`${event.target.dataset.id}-sponsor_2`]
    }  else if (event.target.name === 'sponsor_banner') {
      v = this.state[`${event.target.dataset.id}-sponsor_banner`]
    }  else if (event.target.name === 'sponsor_video') {
      v = this.state[`${event.target.dataset.id}-sponsor_video`]
    }

    console.log(event.target.name, v, event.target.dataset.id)

    axios({
      url: apiUrl + '/edit-quiz',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        id: event.target.dataset.id,
        [event.target.name]: v
      }
    })
    .then(() => {
      this.setState({[`${event.target.dataset.id}-${event.target.name}`]: '' })
      this.props.msgAlert({
        heading: 'Successfully Edited Quiz',
        variant: 'success'
      })
      this.getQuizzes()
    })
    .catch((err) => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    )
  }

  handleDeleteTextField = (event) => {
    axios({
      url: apiUrl + '/edit-quiz',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        id: event.currentTarget.dataset.id,
        text_field: null
      }
    })
    .then(() => {
      this.props.msgAlert({
        heading: 'Successfully Edited Quiz',
        variant: 'success'
      })
      this.getQuizzes()
    })
    .catch((err) => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    )
  }

  sumRegistrationKeys = (quiz) => {
    let count = 0;
    for (let key in quiz) {
        if (key.startsWith("registration")) {
            count++;
        }
    }
    return count;
  }

  sumRegistrationValues = (quiz) => {
    let total = 0;
    for (let key in quiz) {
        if (key.startsWith("registration")) {
            total += quiz[key];
        }
    }
    return total;
  }

  handleCheckboxChange = (quizIndex, key) => {
    const updatedQuizzes = this.state.theQuizzes.map((quiz, index) => {
      if (index === quizIndex) {
        // console.log(quiz, 'quiz')
        // console.log(key, 'key')
        // console.log(quiz[key], 'quiz[key]')
        // this.sumRegistrationKeys(quiz)
        
        return {
          ...quiz,
          [key]: quiz[key] === 1 ? 0 : 1,
        };
      }
      return quiz;
    });

    const sum = this.sumRegistrationValues(updatedQuizzes[quizIndex])

    if (sum === 0) {
      this.props.msgAlert({
        heading: 'At least one field must be selected',
        message: '',
        variant: 'danger'
      })
      return
    }

    this.setState({ theQuizzes: updatedQuizzes });

    // Call a function to update the database
    // Replace with your actual API endpoint and adjust as necessary
    this.updateDatabaseQuizFormFields(updatedQuizzes[quizIndex]);
  }

  updateDatabaseQuizFormFields = (updatedQuiz) => {
    axios({
      url: apiUrl + '/edit-formfields',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        id: updatedQuiz.id,
        first_name: updatedQuiz.registration_first_name,
        last_name: updatedQuiz.registration_last_name,
        high_school: updatedQuiz.registration_high_school,
        company_name: updatedQuiz.registration_company_name,
        mobile_phone: updatedQuiz.registration_mobile_phone,
        birthday: updatedQuiz.registration_birthday,
        education: updatedQuiz.registration_education,
        address_1: updatedQuiz.registration_address_1,
        address_2: updatedQuiz.registration_address_2,
        city: updatedQuiz.registration_city,
        state: updatedQuiz.registration_state,
        zip: updatedQuiz.registration_zip
      }
    })
    .then(() => {
      this.props.msgAlert({
        heading: 'Successfully Updated Field',
        variant: 'success'
      })
      this.getQuizzes()
    })
    .catch((err) => {
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    )
    // axios.post('your-api-endpoint/update-quiz', updatedQuiz)
    //   .then(response => {
    //     console.log('Database updated successfully', response.data);
    //   })
    //   .catch(error => {
    //     console.error('There was an error updating the database:', error);
    //   });
  }

  render () {
    return (
      <React.Fragment>
        <div className='d-flex' style={{ fontWeight: 'bold', fontSize: '2rem', paddingTop: '3rem' }}><span>Your Quizzes</span><Button style={{ marginLeft: '1rem' }} onClick={this.props.showAddQuizModal}>Add Quiz</Button></div>
        <Container className='mt-3' style={{ paddingBottom: '7rem' }}>
          <div className='row row-cols-sm-1 row-cols-lg-2'>
            {this.state.theQuizzes.map((q, i) => (
            <div key={q.id} className='' style={{ borderBottomStyle: 'solid' }}>
              <div className='mb-2 mt-3 d-flex'><div style={{ fontSize: '1.5rem' }}>{i+1}. {q.title}</div><Form data-id={q.id} onSubmit={this.handleDeleteQuiz} className='d-flex'>&nbsp;&nbsp;<div className='d-flex align-items-center'><Button type='submit' style={{ border: 'none', color: 'white', padding: '0', backgroundColor: 'white', margin: '0' }}><DeleteOutlineIcon sx={{ color: 'red', cursor: 'pointer' }}></DeleteOutlineIcon></Button><Form.Check className='' size='sm' required/></div></Form></div>
              <div className='d-flex'>Quiz Link:&nbsp;<div style={{ textDecoration: 'underline', cursor: 'pointer' }} className='' data-id={`${clientUrl}/quizzes/${q.id}`} onClick={() => { navigator.clipboard.writeText(`${clientUrl}/play/${q.id}`); this.props.msgAlert({ heading: 'copied to clipboard', variant: 'success' }) }}>{`${clientUrl}/play/${q.id}`}&nbsp;<ContentCopyIcon></ContentCopyIcon></div></div>
              <div className='d-flex'>Leaderboard Link:&nbsp;<div style={{ textDecoration: 'underline', cursor: 'pointer' }} className='' data-id={`${clientUrl}/leaderboards/${q.id}`} onClick={() => { navigator.clipboard.writeText(`${clientUrl}/leaderboards/${q.id}`); this.props.msgAlert({ heading: 'copied to clipboard', variant: 'success' }) }}>{`${clientUrl}/leaderboards/${q.id}`}&nbsp;<ContentCopyIcon></ContentCopyIcon></div></div>
              <ListGroup variant='flush'>
                <ListGroup.Item><span style={{ fontWeight: 'bold' }}>Date Added:&nbsp;</span> {this.convertTimeStamp(q.timestamp_added)}</ListGroup.Item>
                <ListGroup.Item><span style={{ fontWeight: 'bold' }}>Associated Game:&nbsp;</span> {q.game_name}</ListGroup.Item>
                <ListGroup.Item>
                  <div style={{ width: '100%' }} className='d-flex justify-content-between'>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                      Duration &#40;seconds&#41;:&nbsp;
                      </span>{q.duration}
                    </div>
                    <div className='d-flex'>
                      Edit:&nbsp;
                      <Form className='d-flex' onSubmit={this.handleSubmit} name='duration' data-id={q.id}>
                        <Form.Control required size='sm' placeholder='enter new duration' type='number' name={`${q.id}-duration`} defaultValue='' value={this.state[`${q.id}-duration`]} onChange={this.handleChange} />&nbsp;
                        <Button type='submit' size='sm' variant='primary'>save</Button>
                      </Form>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex'><span style={{ fontWeight: 'bold' }}>Status</span>:&nbsp;&nbsp;<Form><Form.Check data-id={q.id} defaultChecked={q.active === 1 ? true : false } type='switch' onChange={(event) => this.toggleActivation(event.target.checked, event.target.dataset.id, i)}></Form.Check></Form>{q.active === 1 ? 'Active' : 'Inactive'}</ListGroup.Item>
                <ListGroup.Item className='d-flex'>
                  <div style={{ width: '100%' }} className='d-flex justify-content-between'>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Sponsor&nbsp;&#40;Middle Position 300X250&#41;:&nbsp;
                      </span>{!q.sponsor_name ? 'None' : q.sponsor_name}
                    </div>
                    <div className='d-flex'>
                      Edit:&nbsp;
                      <Form className='d-flex' onSubmit={this.handleSubmit} name='sponsor' data-id={q.id}>
                        <Form.Select required size='sm' name={`${q.id}-sponsor`} onChange={this.handleChange}>
                          <option></option>
                          <option>None</option>
                          {this.state.theSponsors.map((s) => (
                            s.video === 0 ? <option key={s.aws_key} value={s.aws_key}>{s.name}</option> : ''
                          ))}
                        </Form.Select>&nbsp;
                        <Button type='submit' size='sm' variant='primary'>save</Button>
                      </Form>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex'>
                  <div style={{ width: '100%' }} className='d-flex justify-content-between'>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                      Sponsor2&nbsp;&#40;Middle Position 300X250&#41;:&nbsp;
                      </span>{!q.sponsor_2_name ? 'None' : q.sponsor_2_name}
                    </div>
                    <div className='d-flex'>
                      Edit:&nbsp;
                      <Form className='d-flex' onSubmit={this.handleSubmit} name='sponsor_2' data-id={q.id}>
                        <Form.Select required size='sm' name={`${q.id}-sponsor_2`} onChange={this.handleChange}>
                          <option></option>
                          <option>None</option>
                          {this.state.theSponsors.map((s) => (
                            s.video === 0 ? <option key={s.aws_key} value={s.aws_key}>{s.name}</option> : ''
                          ))}
                        </Form.Select>&nbsp;
                        <Button type='submit' size='sm' variant='primary'>save</Button>
                      </Form>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex'>
                  <div style={{ width: '100%' }} className='d-flex justify-content-between'>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Sponsor Banner&nbsp;&#40;Top Position 970X90&#41;:&nbsp;
                      </span>{!q.sponsor_banner_name ? 'None' : q.sponsor_banner_name}
                    </div>
                    <div className='d-flex'>
                      Edit:&nbsp;
                      <Form className='d-flex' onSubmit={this.handleSubmit} name='sponsor_banner' data-id={q.id}>
                        <Form.Select required size='sm' name={`${q.id}-sponsor_banner`} onChange={this.handleChange}>
                          <option></option>
                          <option>None</option>
                          {this.state.theSponsors.map((s) => (
                            s.video === 0 ? <option key={s.aws_key} value={s.aws_key}>{s.name}</option> : ''
                          ))}
                        </Form.Select>&nbsp;
                        <Button type='submit' size='sm' variant='primary'>save</Button>
                      </Form>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex'>
                  <div style={{ width: '100%' }} className='d-flex justify-content-between'>
                    <div>
                      <span style={{ fontWeight: 'bold' }}>
                        Sponsor Video&nbsp;&#40;End of Quiz&#41;:&nbsp;
                      </span>{!q.sponsor_video_name ? 'None' : q.sponsor_video_name}
                    </div>
                    <div className='d-flex'>
                      Edit:&nbsp;
                      <Form className='d-flex' onSubmit={this.handleSubmit} name='sponsor_video' data-id={q.id}>
                        <Form.Select required size='sm' name={`${q.id}-sponsor_video`} onChange={this.handleChange}>
                          <option></option>
                          <option>None</option>
                          {this.state.theSponsors.map((s) => (
                            s.video === 1 ? <option key={s.aws_key} value={s.aws_key}>{s.name}</option> : ''
                          ))}
                        </Form.Select>&nbsp;
                        <Button type='submit' size='sm' variant='primary'>save</Button>
                      </Form>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex'>
                  <Form>
                    <Form.Group>
                      <Form.Label style={{ fontWeight: 'bold' }}>Registration Form Fields:</Form.Label>
                        <Row className='d-flex row-cols-2'>
                          {this.formKeys.map((key) => (
                            q.hasOwnProperty(key) && (
                            <Col className='d-flex justify-content-start' key={key}>
                              <Form.Label>
                              {this.formKeysDisplayNames[key]}
                              </Form.Label>
                              &nbsp;
                              <Form.Check 
                                type="checkbox" 
                                checked={q[key] === 1} 
                                onChange={() => this.handleCheckboxChange(i, key)}
                              />
                            </Col>
                            )
                          ))}
                        </Row>
                    </Form.Group>
                  </Form>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex'>
                  <div style={{ width: '100%' }} className=''>
                    <div className='mb-2'>
                      <span style={{ fontWeight: 'bold' }}>
                        Text Field:
                      </span>
                    </div>
                    <div className='mb-2' style={{ maxHeight: '250px', overflow: 'auto' }}>
                    <TextEditor originalText={q.text_field} data-id={q.id} name={`${q.id}-text_field`} handleChangeText={(name, value) => { this.handleChangeText(name, value) }}/>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <Button data-id={q.id} name={`text_field`} size='sm' variant='primary' onClick={this.handleSubmit}>save</Button>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className='d-flex'>
                  <div style={{ width: '100%' }} className=''>
                    <div className='mb-2'>
                      <span style={{ fontWeight: 'bold' }}>
                        Terms and Conditions:
                      </span>
                    </div>
                    <div className='mb-2' style={{ maxHeight: '250px', overflow: 'auto' }}>
                    <TextEditor originalText={q.terms} data-id={q.id} name={`${q.id}-terms`} handleChangeText={(name, value) => { this.handleChangeText(name, value) }}/>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <Button data-id={q.id} name={`terms`} size='sm' variant='primary' onClick={this.handleSubmit}>save</Button>
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
              <div style={{ fontSize: '1.5rem' }}>Reports: &#40;Quiz: {q.title} | Game: {q.game_name}&#41;</div>
              <Reports {...this.state} {...this.props} quizData={q}></Reports>
            </div>
            ))}
          </div>
        </Container>
        <AddQuizModal getQuizzes={this.getQuizzes} {...this.props}></AddQuizModal>
      </React.Fragment>
    )
  }
}

export default Quizzes