import React, { useState, useEffect } from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig';
import TextEditor from './TextEditor';
import { Button, Container, Row } from 'react-bootstrap';

const Terms = (props) => {
   const [text, setText] = useState('')
   const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        axios({
            method: 'GET',
            url: apiUrl + `/quizbee-terms`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
            }
        })
            .then((res) => {
                setText(res.data.data[0].terms)
                setLoaded(true)
            })
            .catch(() => {
                props.msgAlert({
                    heading: 'Server Error',
                    message: 'Please try again later.',
                    variant: 'danger'
                })
            })
    }, [])
 
   const handleChangeText = (name, value) => {
       setText(value);
   }

   const submitText = () => {
        axios({
            method: 'POST',
            url: apiUrl + `/quizbee-terms`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
            },
            data: {
                terms: text
            }
        })
            .then(() => {
                props.msgAlert({
                    heading: 'Terms Updated',
                    message: '',
                    variant: 'success'
                })
            })
            .catch(() => {
                props.msgAlert({
                    heading: 'Server Error',
                    message: 'Please try again later.',
                    variant: 'danger'
                })
            })
   }

    return (
        <div className=''>
            {loaded &&
            <Container fluid>
                <Row className='text-center'>
                <h5 className='mb-5'>Quizbee Terms</h5>
                <TextEditor originalText={text} data-id='' name='text' value={text} handleChangeText={handleChangeText} />
                </Row>
                <Row className='mt-5'>
                <Button onClick={()=> submitText()} style={{ float: 'right' }} className='mt-2' variant='primary'>Save</Button>
                </Row>
            </Container>
            }
        </div>
    );
}

export default Terms;