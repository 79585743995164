import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import { v4 as uuidv4 } from 'uuid'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

class AddSponsorModal extends React.Component {
  state = {
    name: '',
    file: '',
    vname: '',
    vlink: '',
    webLink: '',
    key: 'image'
  }

  handleChange = (event) => {
    if (event.target.files) {
      const file = event.target.files[0]
      this.setState({ [event.target.name]: file })
    } else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData()
    data.append('file', this.state.file)
    data.append('name', this.state.name)
    data.append('webLink', this.state.webLink)
    data.append('cognitoId', this.props.userData[0].cognitoId)
    data.append('video', 0)
    axios({
      url: apiUrl + '/new-sponsor',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: data
    })
    .then(() => { 
      this.setState({ name: '', file: '', webLink: '' })
      this.props.msgAlert({
        heading: 'Success',
        message: 'Sponsor Successfully Added',
        variant: 'success'
      })
  })
    .then(() => this.props.closeAddSponsorModal())
    .then(() => this.props.getSponsors())
    .catch((err) => {
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  handleSubmitVideo = (event) => {
    event.preventDefault()
    axios({
      url: apiUrl + '/new-sponsor-video',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        name: this.state.vname,
        link: this.state.vlink,
        video: 1,
        cognitoId: this.props.userData[0].cognitoId,
        id: uuidv4(),
        webLink: this.state.webLink
      }
    })
    .then(() => { 
      this.setState({ vname: '', vlink: '', webLink: '' })
      this.props.msgAlert({
        heading: 'Success',
        message: 'Sponsor Successfully Added',
        variant: 'success'
      })
  })
    .then(() => this.props.closeAddSponsorModal())
    .then(() => this.props.getSponsors())
    .catch((err) => {
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  render () {
    return (
      <Modal show={this.props.addSponsorModal} onHide={() => { this.props.closeAddSponsorModal(); this.setState({ name: '', file: '', vname: '', vlink: '', webLink: '' }) }}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Sponsor</Modal.Title>
        </Modal.Header>
        <Tabs activeKey={this.state.key} onSelect={(k) => this.setState({ key: k })} fill>
          <Tab eventKey='image' title='Image'>
            <Modal.Body>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Sponsor Name</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder=""
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Sponsor Website Link &#40;requires the full link, example below.&#41;</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="https://www.quizbee.fun"
                    name="webLink"
                    value={this.state.webLink}
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Image Upload</Form.Label>
                  <Form.Control
                    type='file'
                    name='file'
                    onChange={this.handleChange}
                    accept='image/*'
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3 d-flex justify-content-end">
                  <Button type='submit' variant='primary'>Add Image</Button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Tab>
          <Tab eventKey='video' title='Video'>
            <Modal.Body>
              <Form onSubmit={this.handleSubmitVideo}>
                <div>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>Sponsor Name</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder=""
                    name="vname"
                    value={this.state.vname}
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Video Link &#40;requires the full link, example below.&#41;</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='https://youtu.be/hCytn9LEs-U'
                    name="vlink"
                    value={this.state.vlink}
                    onChange={this.handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3 d-flex justify-content-end">
                  <Button type='submit' variant='primary'>Add Video</Button>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Tab>
        </Tabs>
      </Modal>
    )
  }
}

export default AddSponsorModal