import React from 'react'

const OptionalText = ({ quiz }) => {

    const sethtml = () => {
        return { __html: quiz[0].text_field }
      }


    return (
        <div style={{ fontSize: '9.5px', color: 'white' }} dangerouslySetInnerHTML={sethtml()}/>
    )
}

export default OptionalText