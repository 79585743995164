import { React, useEffect, useState } from 'react';
import { Modal, ListGroup, ListGroupItem } from 'react-bootstrap';
import axios from 'axios';
import apiUrl from '../../../apiConfig';
import { calculateScore } from '../HelperFunctions/calculateScore';



const ScoresModal = ({quiz, player, scoresModal, closeScoresModal, msgAlert}) => {
    const [scores, setScores] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        axios({
            url: `${apiUrl}/quiz/${quiz[0].id}/registrant/${player.email}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
            }
        })
            .then((res) => {
                setScores(res.data.scores)
                setLoaded(true)
            })
            .catch(() => {
                msgAlert({
                    heading: 'No Scores',
                    message: 'You have not played this quiz yet.',
                    variant: 'danger'
                })
            })
    }, [msgAlert, player.email, quiz])

    const convertTime = (unix) => {
        const timeObj = new Date(parseInt(unix)).toLocaleString()
        return timeObj
    }

    return (
        <Modal size='lg' centered show={scoresModal} onHide={closeScoresModal} style={{ borderRadius: '0' }}>
            {loaded &&
            <>
                <Modal.Header closeButton>
                    <Modal.Title>{player.first_name || player.firstName}'s Previous Scores</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {scores.length > 0 &&
                        <div style={{ color: 'white', maxHeight: '500px', overflowY: 'scroll' }} className='row row-cols-sm-1 row-cols-lg-4'>
                        {scores.map((s) => (
                            <ListGroup key={s.id} style={{ marginBottom: '.5rem' }}>
                            <ListGroupItem variant='dark'>
                                <div>
                                Date: &nbsp;{convertTime(s.timestamp)}
                                </div>
                                <div className='d-flex justify-content-end'>
                                Score: &nbsp;<span style={{ fontWeight: 'bold' }}>{calculateScore(s.quiz_duration, s.total_correct, s.total_incorrect)}</span>
                                </div>
                            </ListGroupItem>
                            </ListGroup>
                        ))}
                        </div>
                        }
                        {scores.length === 0 &&
                        <div className='d-flex justify-content-center'>
                            <h5>No previous scores</h5>
                        </div>
                        }
                    </>
                </Modal.Body>
            </>
            }
        </Modal>
    );
}

export default ScoresModal;