import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'

class DeleteGameModal extends React.Component {
  state={}

  deleteGame = () => {
    axios({
      url: apiUrl + '/delete-game',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        id: this.props.selectedGameId
      }
    })
    .then(() => this.props.deleteGameModalClose())
    .then(() => this.props.getGames())
    .catch((err) => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  render () {
    return (
      <Modal show={this.props.deleteGameModal} onHide={this.props.deleteGameModalClose}>
        <Modal.Header closeButton>
          <Modal.Title><ReportProblemIcon></ReportProblemIcon>&nbsp;CONFIRM!&nbsp;<ReportProblemIcon></ReportProblemIcon></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to PERMANENTLY delete this Game, {this.props.selectedGameName}?
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-evenly'>
          <Button variant='danger' onClick={this.deleteGame}>Yes</Button>
          <Button variant='outline-primary' onClick={this.props.deleteGameModalClose}>No</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default DeleteGameModal