import { React, useEffect, useState } from 'react';
import axios from 'axios';
import apiUrl from '../../../apiConfig';
import { Container, Row, Col, Button } from 'react-bootstrap';
import SponsorVideo from '../SponsorContent/SponsorVideo';
import ScoresModal from '../PlayerAuth/ScoresModal';
import LeaderBoardPostGame from '../LeaderBoard/LeaderBoardPostGame';
import OptionalText from '../SponsorContent/OptionalText';
import { calculateScore } from '../HelperFunctions/calculateScore';

const PostGame = ({ player, quiz, score, incorrect, playAgain, msgAlert, questions }) => {
    const [scoresModal, setScoresModal] = useState(false);
    const [leaderBoardModal, setLeaderBoardModal] = useState(false);


    useEffect(() => {
        axios({
            url: apiUrl + '/plays',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
            },
            data: {
                registrant: player.email,
                quiz: quiz[0].id,
                duration: quiz[0].duration,
                incorrect: incorrect,
                correct: score,
                total: questions.length,
                timestamp: Date.parse(new Date())
            }
        })
            .then(() => {
                msgAlert({
                    heading: 'Scores Saved',
                    message: '',
                    variant: 'success'
                })    
            })
            .catch(() => {
                msgAlert({
                    heading: 'Server Error',
                    message: 'Please try again later.',
                    variant: 'danger'
                })
            })
    }, []);

    const closeScoresModal = () => {
        setScoresModal(false)
    };

    return (
        <Container fluid>
            <Row className='mt-3' style={{ color: 'white' }}>
                <Col className='text-center'> 
                    <h1>{quiz[0].title}</h1>
                    <h2 className='mt-5'>Game Over</h2>
                    <h3>Correct: {score} | Incorrect: {incorrect} | Final Score: {calculateScore(quiz[0].duration, score, incorrect)}</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <SponsorVideo quiz={quiz} />
                </Col>
            </Row>
            <Row className="justify-content-md-center text-center mt-3">
                <Col xs={12} md="auto" className="mb-2 mb-md-0">
                    <Button style={{ width: '130px', borderRadius: '0' }} variant="light" onClick={() => playAgain()}>Play Again</Button>
                </Col>
                <Col xs={12} md="auto" className="mb-2 mb-md-0">
                    <Button style={{ width: '130px', borderRadius: '0' }} variant="light" onClick={() => setScoresModal(true)}>My Scores</Button>
                </Col>
                <Col xs={12} md="auto">
                    <Button style={{ width: '130px', borderRadius: '0' }} variant="light" onClick={() => setLeaderBoardModal(true)}>Leaderboard</Button>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col className='d-flex justify-content-start'>
                    <OptionalText quiz={quiz} />
                </Col>
            </Row>
            {scoresModal && 
            <ScoresModal quiz={quiz} player={player} scoresModal={scoresModal} closeScoresModal={closeScoresModal} msgAlert={msgAlert} />
            }
            {leaderBoardModal &&
            <LeaderBoardPostGame quiz={quiz} leaderBoardModal={leaderBoardModal} closeLeaderBoardModal={() => setLeaderBoardModal(false)} msgAlert={msgAlert} />
            }
        </Container>
    )
}

export default PostGame;