import { React, useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import axios from 'axios';
import apiUrl from '../../apiConfig';
import { Container, Row, Spinner, Col } from 'react-bootstrap';
import formatQuestionsHelper from './HelperFunctions/formatQuestionsHelper';
import Background from '../../images/bg_dark.jpg';
import ErrorIcon from '@mui/icons-material/Error';
import SponsorBanner from './SponsorContent/SponsorBanner';
import SponsorMain from './SponsorContent/SponsorMain';
import PlayerAuth from './PlayerAuth/PlayerAuth';
import Game from './TheGame/Game';
import OptionalText from './SponsorContent/OptionalText';
import { TimerProvider } from './TheGame/TimerProvider';

const TriviaGamePlay = ({ msgAlert } ) => {
    const { id } = useParams();
    const [loaded, setLoaded] = useState(false);
    const [quizData, setQuizData] = useState('');
    const [quizQuestions, setQuizQuestions] = useState('');
    const [to404, setTo404] = useState(false);
    const [player, setPlayer] = useState('');
    const [startGame, setStartGame] = useState(false);

    useEffect(() => {
        const formatQuestions = async(questions) => {
            const shuffled = shuffleQuestions(questions);
            const formatted = await formatQuestionsHelper(shuffled);
            if (formatted) {
                if (formatted.length < 1) {
                    setQuizQuestions(false);
                } else {
                setQuizQuestions(formatted);
                }
                setLoaded(true);
            } else {
                msgAlert({
                    heading: 'Server Error',
                    message: 'Please try again later.',
                    variant: 'danger',
                });
            }
        }

        axios({
            method: 'GET',
            url: apiUrl + `/quiz/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
            },
        })
            .then((res) => {
                if (res.data.quiz[0]) {
                    setQuizData(res.data.quiz);
                    axios({
                        method: 'GET',
                        url: apiUrl + `/questions/${res.data.quiz[0].usergame_id}`,
                    })
                        .then((res) => {
                            formatQuestions(res.data.quizData);
                        })
                        .catch(() => {
                            msgAlert({
                                heading: 'Server Error',
                                message: 'Please try again later.',
                                variant: 'danger',
                            });
                        });
                    // shuffle the questions
                    // format the questions
                    // set the questions
                } else {
                    setTo404(true);
                }
            })
            .catch(() => {
                msgAlert({
                    heading: 'Server Error',
                    message: 'Please try again later.',
                    variant: 'danger',
                });
            });
    }, [id, msgAlert]);


    const shuffleQuestions = (questions) => {
        for (let i = questions.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [questions[i], questions[j]] = [questions[j], questions[i]];
        }
        return questions;
    };

    return (
    <Container fluid className='d-flex' style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRrepeat: 'repeat-y', width: '100%', minHeight: '100vh'}}>
    {to404 ? (
        <Navigate to="/404" />
    ) : loaded ? (
        <Container fluid className='d-flex'>
            {quizData[0].active ? (
                <Container fluid className='d-flex flex-column'>
                    {startGame &&
                    <TimerProvider initialTime={quizData[0].duration}>
                        <Game quiz={quizData} questions={quizQuestions} player={player} msgAlert={msgAlert} />
                    </TimerProvider>
                    }
                    {!startGame &&
                    <SponsorBanner quiz={quizData} />
                    }
                    {!startGame &&
                    <Container className='d-flex flex-column mt-5 flex-grow-1' >
                        {!startGame &&
                        <Row style={{ color: 'white' }} className='text-center mb-5 mt-2'><h1>{quizData[0].title}</h1></Row>
                        }
                        {!quizQuestions ? (
                            <Row className='d-flex flex-grow-1' style={{ color: 'white' }}>
                                <Row className='d-flex justify-content-center align-items-end'><ErrorIcon style={{ fontSize: 100 }} /></Row>
                                <Row className='text-center'><h2>No Questions Loaded. Please contact the Game Administrator</h2></Row>
                            </Row>
                        ) : (
                            <>
                                {!startGame &&
                                <>
                                    <Container className='mt-2 mb-2 d-flex flex-column justify-content-center'>
                                        <Row className=''>
                                            <Col className='d-flex justify-content-center'>
                                                <PlayerAuth setStartGame={setStartGame} quiz={quizData} player={player} msgAlert={msgAlert} setPlayer={setPlayer} />
                                            </Col>
                                        </Row>
                                        {/* <Row className='mt-5'>
                                            <Col className='d-flex'>
                                                <OptionalText quiz={quizData} />
                                            </Col>
                                        </Row> */}
                                    </Container>
                                    <Container fluid className='mt-5'><SponsorMain quiz={quizData} /></Container>
                                    <Container className='d-flex flex-column justify-content-center'>
                                        {/* <Row className=''>
                                            <Col className='d-flex justify-content-center'>
                                                <PlayerAuth setStartGame={setStartGame} quiz={quizData} player={player} msgAlert={msgAlert} setPlayer={setPlayer} />
                                            </Col>
                                        </Row> */}
                                        <Row className=''>
                                            <Col className='d-flex'>
                                                <OptionalText quiz={quizData} />
                                            </Col>
                                        </Row>
                                    </Container>
                                </>
                                }
                            </>
                        )}
                    </Container>
                    }
                </Container>
            ) : (
                <Container className="d-flex justify-content-center align-items-center">
                    <Row style={{ color: 'white' }}>
                        <Row className='d-flex justify-content-center align-items-end'><ErrorIcon style={{ fontSize: 100 }} /></Row>
                        <Row className='text-center'><h2>Game Not Active. Please contact the Game Administrator</h2></Row>
                    </Row>
                </Container>
            )}
        </Container>
    ) : (
        <Row style={{ width: '100%' }} className='d-flex justify-content-center align-items-center'>
            <Spinner style={{ color: 'white', width: '70px', height: '70px' }} animation="border" role="status">
            </Spinner>
        </Row>
    )}
    </Container>
    );
};

export default TriviaGamePlay;