import { React } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const AnswerSelection = ({ option, letter }) => {
  return (
    <Container className='d-flex flex-column' style={{ height: '100%' }}>
      <Row>
        <Col className='d-flex justify-content-center align-content-center'>
          <div style={{
            width: '40px', 
            height: '40px', 
            borderRadius: '50%', 
            backgroundColor: '#000000',
            borderStyle: 'solid',
            borderColor: '#ffffff',
            borderWidth: '1px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ffffff',
            zIndex: '1',
            fontSize: '24px',
          }} className=''>
            {letter}
          </div>
        </Col>
        </Row>
      <Row className='d-flex flex-grow-1 g-0'
            style={{
            marginTop: '-10px',
            borderRadius: '20px',
            width: '100%',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
        <Col className='text-center break-word' style={{ fontWeight: 'bold', fontSize: '1.3rem', paddingTop: '10px', paddingBottom: '10px', paddingRight: '5px', paddingLeft: '5px' }} >{option.option}</Col>
      </Row>
    </Container>
  )
}

const LongAnswersTemplate = ({ handleOptionSelect, question }) => {
  return (
    <Container fluid style={{ marginTop: '42px' }} className=''>
      <Row className='row-cols-1 row-cols-lg-2' >
          {question.options.map((option, index) => (
            <Col key={option.key} onClick={() => handleOptionSelect(option.isCorrect)} className='mb-3' style={{ cursor: 'pointer' }}>
              <AnswerSelection option={option} letter={String.fromCharCode(65 + index)}/>
            </Col>
          ))}
      </Row>
    </Container>
  )
}

export default LongAnswersTemplate