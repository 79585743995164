import React from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import apiUrl from '../../../apiConfig'
// import Container from 'react-bootstrap/Container'
// import Button from 'react-bootstrap/Button'
import SuperAdmin from './SuperAdmin/SuperAdmin'
import AdminClient from './AdminClient/AdminClient'

class Authenticated extends React.Component {
  state = {
    toHome: false,
    userData: ''
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/user',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.user.cognitoId
      }
    })
    .then((res) => this.setState({ userData: res.data.user }))
    .catch(() => {
      this.props.clearUser()
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  signOut = (event) => {
    event.preventDefault()
    console.log('here')
    axios({
      url: apiUrl + '/sign-out',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.user.cognitoId
      }
    })
    .then(() => this.props.clearUser())
    .then(() =>
      this.props.msgAlert({
        heading: 'Signed Out',
        message: '',
        variant: 'success'
      }))
    .then(() => this.setState({ toHome: true }))
    .catch((error) => {
      this.setState({ error: error.response.data.error })
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Sign Out Failure',
        message: error.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  render () {
    if (this.state.toHome) {
      return <Navigate to='/' />
    }
    return (
      <React.Fragment>
        {this.state.userData &&
        <React.Fragment>
          {this.state.userData[0].super === 1 &&
            <SuperAdmin {...this.state} {...this.props} signOut={this.signOut} showPasswordModal={this.props.showPasswordModal} msgAlert={this.props.msgAlert}></SuperAdmin>
          }
          {this.state.userData[0].super !== 1 &&
            <AdminClient {...this.state} {...this.props} signOut={this.signOut} helpEmail={this.props.helpEmail} showPasswordModal={this.props.showPasswordModal}></AdminClient>
          }
        </React.Fragment>
        }
        {/* <Button onClick={this.signOut}>signout</Button> */}
        {/* <Button onClick={() => console.log(this.state.userData[0].firstName)}>gd</Button> */}
      </React.Fragment>
    )
  }
}

export default Authenticated