import React from 'react'
import axios from 'axios'
import apiUrl from '../../../apiConfig'
import io from 'socket.io-client'
import { useParams, Navigate } from 'react-router-dom'
import { Container, ListGroup, ListGroupItem, Col } from 'react-bootstrap' 
import setLeaderBoard from '../HelperFunctions/setLeaderBoard'
import backgroundImage from '../../../images/bg_dark.jpg'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Ordinal from 'ordinal'
import { calculateScore } from '../HelperFunctions/calculateScore'
let socket

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />
}

class LeaderBoardLocal extends React.Component {
  state = {
    leaders: [],
    quiz: '',
    loaded: false,
    to404: false
  }

  componentDidMount () {
    socket = io.connect(apiUrl)
    socket.on('updatedBoard', () => {
      this.updateLeaderboard()
    })
    axios({
      url: apiUrl + `/leaderboard/local/${this.props.params.id}`,
      method: 'GET'
    })
    .then(res => this.setState({ leaders: setLeaderBoard(res.data.scores)}))
    .then(() => this.getQuiz())
    .catch((err) => {
      this.props.msgAlert({
        heading: 'Something Went Wrong',
        variant: 'danger'
      })
    }
    )
  }

  updateLeaderboard = () => {
    axios({
      url: apiUrl + `/leaderboard/local/${this.props.params.id}`,
      method: 'GET'
    })
    .then(res => this.setState({ leaders: setLeaderBoard(res.data.scores)}))
    .catch(() => { 
      this.props.msgAlert({
        heading: 'Something Went Wrong',
        variant: 'danger'
      })
    }
    )
  }

  getQuiz = () => {
    axios({
      url: apiUrl + `/quiz/${this.props.params.id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
      }
    })
    .then((res) => {
      if (res.data.quiz[0]) {
      this.setState({ quiz: res.data.quiz, loaded: true })
      } else {
        this.setState({ to404: true })
      }
    })
    .catch(() => { 
      this.props.msgAlert({
        heading: 'Something Went Wrong',
        variant: 'danger'
      })
    }
    )
  }

  convertToOrdinal = (num) => {
    return Ordinal(num)
  }

  render () {
    if (this.state.to404) {
      return <Navigate to='/404' />
    }
    return (
      <>
        <Container fluid style={{ backgroundImage: `url(${backgroundImage})`, backgroundPositionX: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '100vh' }}>
          <div>
            {this.state.loaded &&
            <div style={{ paddingTop: '1rem' }}>
              <div className='quiz-title' style={{ textAlign: 'center', color: 'white' }}>{this.state.quiz[0].title} {this.state.quiz[0].game_name} Leaderboard!</div>
              <Container className='mt-3'>
                <div className='d-flex justify-content-center' style={{ borderStyle: 'solid', borderColor: 'white', paddingTop: '1rem', paddingBottom: '1rem', backgroundColor: '#343a40' }}>
                  <EmojiEventsIcon sx={{ fontSize: 100, color: '#d2ac47' }}></EmojiEventsIcon>
                </div>
                <div style={{ color: 'white', fontWeight: 'bold', maxHeight: '450px', overflowY: 'scroll' }}>
                  <ListGroup style={{ borderRadius: '0' }}>
                    <div >
                    {this.state.leaders.map((l,i) => (
                      <ListGroupItem style={{ paddingLeft: '6rem', paddingRight: '6rem' }} key={i}>
                        <div className='' style={{ fontSize: '2.2rem', textAlign: 'center', color: '#343a40' }}>{this.convertToOrdinal(i+1)}</div>
                        {l.map((v,j) => (
                          <div key={v.play_id}>
                            <ListGroupItem key={v.play_id}>
                              <div className='row'>
                                <Col xs={12} lg={4} style={{ display: 'flex', justifyContent: 'center' }}>Name: &nbsp;{v.first_name} {v.last_name}</Col>
                                <Col xs={12} lg={4} style={{ display: 'flex', justifyContent: 'center' }}>score: {calculateScore(v.quiz_duration, v.total_correct, v.total_incorrect)}&nbsp;</Col>
                                <Col xs={12} lg={4} style={{ display: 'flex', justifyContent: 'center' }}>{v.high_school ? `School:  ${v.high_school}` : ''}</Col>
                              </div>
                            </ListGroupItem>
                          </div>
                        ))}
                      </ListGroupItem>
                    ))}
                    </div>
                  </ListGroup>
                </div>
              </Container>
            </div>
            }
          </div>
          {this.state.leaders.length < 1 &&
          <div style={{ textAlign: 'center', color: 'white', marginTop: '2rem' }}>No Plays Have Been Recorded as Yet.</div>
          }
        </Container>
      </>
    )
  }
}

export default withParams(LeaderBoardLocal)