import { React } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const AnswerSelection = ({ option, letter }) => {
  return (
    <Container className='mb-2'>
      <Row className='d-flex justify-content-center'>
        <Row>
          <Col className='d-flex justify-content-center align-content-center'>
            <div style={{
              width: '50px', 
              height: '50px', 
              borderRadius: '50%', 
              backgroundColor: '#000000',
              borderStyle: 'solid',
              borderColor: '#ffffff',
              borderWidth: '1px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#ffffff',
              zIndex: '1',
              fontSize: '24px',
            }} className=''>
              {letter}
            </div>
          </Col>
        </Row>
        <Row className='d-flex align-items-center '
             style={{
              marginTop: '-20px',
              borderRadius: '20px',
              width: '200px', height: '200px',
              backgroundColor: 'white',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
              }}>
          <Col className='text-center break-word' style={{ fontWeight: 'bold', fontSize: '1.3rem' }} >{option.option}</Col>
        </Row>
      </Row>
    </Container>
  )
}

const ShortAnswersTemplate = ({ handleOptionSelect, question }) => {
  return (
    <Container fluid className=''>
      <Row className='g-0 row-cols-xs-1 row-cols-sm-2 row-cols-md-4' style={{ width: '100%' }}>
          {question.options.map((option, index) => (
            <Col key={option.key} onClick={() => handleOptionSelect(option.isCorrect)} className='mb-3' style={{ cursor: 'pointer' }}>
               <AnswerSelection option={option} letter={String.fromCharCode(65 + index)}/>
            </Col>
          ))}
      </Row>
    </Container>
  )
}

export default ShortAnswersTemplate