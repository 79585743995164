import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'

class ClientGamesModal extends React.Component {
  state = {
    games: [],
    assignedGames: [],
    availableGames: [],
    assignGameId: '',
    viewGames: false
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/get-games',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    .then(res => this.setState({ games: res.data.games }))
  }

  getUnassignedGames = () => {
    const allGames = this.state.games
    const assignedGames = this.state.assignedGames
    const availableGames = []
    let match = false
    allGames.forEach((g) => {
      assignedGames.forEach((v) => {
        if (g.id === v.game_id) {
          match = true
        }
      })
      if (!match) {
        availableGames.push(g)
      }
      match = false
    })
    this.setState({ availableGames: availableGames })
  }

  getAssignedGames = () => {
    this.setState({ viewGames: true })
    axios({
      url: apiUrl + '/get-usersgames',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        userId: this.props.selectedUserId
      }
    })
    .then(res => this.setState({ assignedGames: res.data.games },
      () => this.getUnassignedGames()
      ))
  }

  submitDeleteAssignedGames = (event) => {
    event.preventDefault()
    console.log(event.target.dataset.usergameid)
    axios({
      url: apiUrl + '/remove-combo',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        user_games_id: event.target.dataset.usergameid
      }
    })
    .then(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Game Deleted',
        message: '',
        variant: 'success'
      })
    }
    )
    .then(() => this.getAssignedGames())
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const splitOne = this.state.assignGameId.split(' ')
    const splitTwo = splitOne[2].split(' ')
    const splitThree = splitTwo[0].split(')')
    const id = parseFloat(splitThree)
    console.log(id)
    axios({
      url: apiUrl + '/add-combo',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        gameId: id,
        userId: this.props.selectedUserId
      }
    })
    .then(() => {
      this.setState({ assignedGames: [], availableGames: [], assignGameId: '', viewGames: false })
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Game Added',
        message: '',
        variant: 'success'
      })
    }
    )
    .then(() => this.props.closeClientGamesModal())
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render () {
    return (
      <Modal show={this.props.clientGamesModal} onHide={() => {this.props.closeClientGamesModal(); this.setState({assignedGames: [], availableGames: [], assignGameId: '', viewGames: false})}}>
        <Modal.Header closeButton>
          <Modal.Title>Assign or Remove Games for {this.props.selectedUserName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className='d-flex justify-content-center'><Button onClick={this.getAssignedGames}>View Assigned Games</Button></div>
            {this.state.assignedGames.length >= 1 &&
              <ListGroup className="mt-2">
                {this.state.assignedGames.map((game) => (
                  <ListGroup.Item key={game.id} data-id={game.id}>
                    <div className='d-flex justify-content-between'>
                      <span>{game.game_name}</span>
                      <Form data-usergameid={game.id} data-gameid={game.game_id} onSubmit={this.submitDeleteAssignedGames}>
                        <div className='d-flex'>
                          <Form.Check required type="checkbox" label="Check to Delete" style={{ marginRight: '1rem' }}/>
                          <Button variant='outline-danger' type='submit' data-gameid={game.game_id} data-usergameid={game.id} style={{ textDecoration: 'underline', border: 'none', padding: '0', margin: '0', backgroundColor: 'white' }}>delete</Button>
                        </div>
                      </Form>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            }
          </React.Fragment>
        </Modal.Body>
        <Modal.Body>
          {this.state.viewGames &&
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Assign a Game</Form.Label>
              <Form.Select required name='assignGameId' onChange={this.handleChange}>
                <option></option>
                {this.state.availableGames.map((game) => (
                  <option key={game.id} data-id={game.id} data-superadmincognitoid={game.superAdminCognitoId}>{game.name} {`(id: ${game.id})`}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 d-flex justify-content-end">
              <Button type='submit' variant='primary'>Assign</Button>
            </Form.Group>
          </Form>
          }
        </Modal.Body>
      </Modal>
    )
  }
}

export default ClientGamesModal