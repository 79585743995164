import React from 'react'

const SponsorBanner = (props) => {
    return (
        <div className='d-flex justify-content-center'>
            {props.quiz[0].sponsor_banner_id &&
                <a href={props.quiz[0].sponsor_banner_web_link}><img alt='banner' src={props.quiz[0].sponsor_banner_link} className='sponsor-banner'/></a>
            }
        </div>
    )
}

export default SponsorBanner