import React, { useState, useEffect } from 'react';
import { Row, Container, Spinner, Col } from 'react-bootstrap';
import axios from 'axios'; // Import Axios
import apiUrl from '../../../apiConfig'; // Import apiUrl

function PreGame({ gameInPlay, msgAlert, quiz, player }) {
    const startNumber = 5;
    const [timeLeft, setTimeLeft] = useState(startNumber);
    const [playCount, setPlayCount] = useState(0); // State to store number of plays
    const [loading, setLoading] = useState(true); // State to manage loading status

    useEffect(() => {
        // Fetch number of plays from the API
        axios({
          url: `${apiUrl}/quiz/${quiz[0].id}/registrant/${player.email}`,
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
          }
        })
            .then(response => {
                setPlayCount(response.data.scores.length); // Assuming the response has a 'plays' field
                setLoading(false);
            })
            .catch(() => {
                msgAlert({
                    heading: 'Error',
                    message: 'Please try again later.',
                    variant: 'danger'
                })

                setLoading(false);
            });
    }, []);

    useEffect(() => {
        // Only start the countdown if startNumber is greater than 0 and plays are less than 5
        if (startNumber > 0 && playCount < 5) {
            const intervalId = setInterval(() => {
                setTimeLeft((prevTimeLeft) => {
                    if (prevTimeLeft <= 1) {
                        clearInterval(intervalId); // Stop the countdown
                        gameInPlay(); // Update the state to indicate completion
                        return 0; // Reset timeLeft to 0
                    }
                    return prevTimeLeft - 1; // Decrement timeLeft
                });
            }, 1000); // Decrement every second

            // Cleanup function to clear the interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [gameInPlay, playCount]); // Include playCount in dependency array

    if (loading) {
        return <Spinner animation="border" style={{ color: 'white' }} />; // Show loading spinner while fetching play count
    }

    if (playCount >= 5) {
        return (
            <Container fluid className='d-flex flex-column justify-content-center align-items-center' style={{ height: '100vh', color: 'white' }}>
                <h2>You have reached the maximum plays for this Game</h2>
            </Container>
        );
    }

    // Render the countdown if plays are less than 5
    return (
      <Container fluid className='d-flex flex-column'>
        <Row className='d-flex'>
            <Col className='d-flex justify-content-center' style={{ width: '100%', color: 'white' }}><div className='d-flex align-items-center'><h2>Countdown To Start</h2>&nbsp;&nbsp;<Spinner style={{ width: '25px', height: '25px', color: 'white' }} animation="border" /></div></Col>
        </Row>
        <Row style={{ color: 'white' }} className='d-flex flex-grow-1 align-items-center text-center'>
            <h2 style={{ fontSize: '100px' }}>{timeLeft}</h2>
        </Row>
      </Container>
    );
}

export default PreGame;



// function PreGame({ gameInPlay }) {
//     const startNumber = 5;
//     const [timeLeft, setTimeLeft] = useState(startNumber);
  
//     useEffect(() => {
//       // Only start the countdown if startNumber is greater than 0
//       if (startNumber > 0) {
//         const intervalId = setInterval(() => {
//           setTimeLeft((prevTimeLeft) => {
//             if (prevTimeLeft <= 1) {
//               clearInterval(intervalId); // Stop the countdown
//               gameInPlay(); // Update the state to indicate completion
//               return 0; // Reset timeLeft to 0
//             }
//             return prevTimeLeft - 1; // Decrement timeLeft
//           });
//         }, 1000); // Decrement every second
  
//         // Cleanup function to clear the interval on component unmount
//         return () => clearInterval(intervalId);
//       }
//     }, [gameInPlay]); 
  
//     return (
//       <Container fluid className='d-flex flex-column'>
//         <Row className='d-flex'>
//             <Col className='d-flex justify-content-center' style={{ width: '100%' }}><div className='d-flex align-items-center'><h2>Countdown To Start</h2>&nbsp;&nbsp;<Spinner style={{ width: '25px', height: '25px' }} animation="border" /></div></Col>
//         </Row>
//         <Row className='d-flex flex-grow-1 align-items-center text-center'>
//             <h2 style={{ fontSize: '100px' }}>{timeLeft}</h2>
//         </Row>
//       </Container>
//     );
//   }

// export default PreGame;

