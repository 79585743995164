
function processQuizData(questions) {
    if (questions.length >= 1) {
      const formattedArrayOfQuestion = questions.map((individualQuestion) => {
        const {
          question,
          correct_answer,
          id,
          usergame_id,
          ...incorrectAnswers // get remaining incorrect answers as a seperate object, and iterate over it to format it
        } = individualQuestion;
        const options = [];
        for (let option in incorrectAnswers) {
          if (incorrectAnswers[option])
            options.push({ option: incorrectAnswers[option], isCorrect: false, key: incorrectAnswers[option] }); // if it isn't empty, add that in
        }
        options.push({ option: correct_answer, isCorrect: true, key: correct_answer }); // add the correct answer in, and now we have all our options in, formatted correctly
        return {
          options,
          question,
          id
        };
      });
      return formattedArrayOfQuestion;
    } else {
      return []
    }
  }
  
  export default async function formatQuestionsHelper(questions) {
    // mock data
    // console.log(quizData);
    return processQuizData(questions); // assuming the array of questions comes in an object as a property labelled quizData
    // return quizData;
    //
    // actual case will look something like
    // try {
    //   const data = await axios.get(url);
    // } catch (error) {
    //   throw new Error("Something went wrong while fetching data");
    // }
  }