
import React, { useState, useEffect } from 'react';
import { Modal, Button, Carousel } from 'react-bootstrap';
import axios from 'axios';
import apiUrl from '../../../../apiConfig';

function TermsModal({ msgAlert, termsModal, closeTermsModal, quiz, setTermsAccepted, setStartGame }) {
  const [index, setIndex] = useState(0);
  const [quizbeeTerms, setQuizbeeTerms] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    axios({
        method: 'GET',
        url: apiUrl + `/quizbee-terms`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
        }
    })
        .then((res) => {
            setQuizbeeTerms(res.data.data[0].terms)
            setLoaded(true)
        })
        .catch(() => {
            msgAlert({
                heading: 'Server Error',
                message: 'Please try again later.',
                variant: 'danger'
            })
        })
    }, [msgAlert]);

  const handleAccept = () => {
    if (index === 0 && quiz[0].terms) {
      setIndex(1); // Move to next term
    } else {
      closeTermsModal(); // Close modal
      setTermsAccepted(true); // Set terms accepted
      setStartGame(true); // Start game
    }
  };

  const renderhtml = (html) => {
    return { __html: html };
  }

  return (
    <Modal show={termsModal} onHide={closeTermsModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Terms of Service</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loaded ? (
          <div>Loading...</div>
        ) : (
          <Carousel activeIndex={index} indicators={false} controls={false} interval={null} style={{ maxHeight: '500px', overflowY: 'scroll' }}>
            <Carousel.Item>
              <div dangerouslySetInnerHTML={renderhtml(quizbeeTerms)} />
              <div className='d-flex justify-content-center'><Button className='mt-3' style={{ float: 'right' }} variant="dark" onClick={handleAccept}>Accept Quizbee Terms</Button></div>
            </Carousel.Item>
            {quiz[0].terms && (
              <Carousel.Item>
                <div dangerouslySetInnerHTML={renderhtml(quiz[0].terms)} />
                <div className='d-flex justify-content-center'><Button className='mt-3' style={{ float: 'right' }} variant="dark" onClick={handleAccept}>Accept Game Terms</Button></div>
              </Carousel.Item>
            )}
          </Carousel>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default TermsModal;