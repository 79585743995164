import { React, useEffect } from 'react';
import { useTimer } from './TimerProvider';

const Timer = ({ gameEnded }) => {
  // const [time, setTime] = useState(initialTime);
  const { time } = useTimer();

  // useEffect(() => {
  //   if (time > 0) {
  //     const interval = setInterval(() => {
  //       setTime((prevTime) => prevTime - 1);
  //     }, 1000);

  //     return () => clearInterval(interval);
  //   } else {
  //     gameEnded()
  //   }
  // }, [time, gameEnded]);

  useEffect(() => {
    if (time === 0) {
      gameEnded();
    }
  }, [time, gameEnded]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    if (hours > 0) {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      } else {
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      }
  };

  return (
    <div className='gradient-text'>
      {formatTime(time)}
    </div>
  );
};

export default Timer;
