import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Clock from '../../../../images/clock.png'
// import Background from '../../../../images/bg_1.png'
import Logo from '../../../../images/quizbee_logo.png'
import Timer from '../../TheGame/Timer'

const QuestionsTemplate = ({ question, quiz, score, gameEnded }) => {
  return (
    <Container style={{ backgroundColor: 'black', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '0 0 20px 20px', borderColor: 'white', borderStyle: 'solid', borderWidth: '10px', borderTopWidth: '0', height: '500px', marginBottom: '-100px' }} className='d-flex flex-column'>
      <Row className='' style={{ paddingTop: '15px', paddingRight: '15px' }}>
        <Col className='d-block d-lg-none'>
          <div>
            <img src={Logo} style={{ width: '50px' }} alt='Quizbee Logo' />
          </div>
        </Col>
        <Col className='d-flex justify-content-end'>
          <div className=''>
            <div className='d-flex align-items-center'>
              <img src={Clock} style={{ width: '35px', height: '40px' }} alt='Clock' />&nbsp;&nbsp;
              <Timer initialTime={quiz[0].duration} gameEnded={gameEnded} />
            </div>
            <div style={{ color: 'green', fontWeight: 'bold', textAlign: 'right' }}>Correct: {score}</div>
          </div>
        </Col>
      </Row>
      <Row className='d-flex align-items-center' style={{ flexGrow: .75 }}>
        <Col className='' style={{ textAlign: 'center', color: 'white', fontSize: '2rem', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
          {question.question}
        </Col>
      </Row>
    </Container>
  )
}

export default QuestionsTemplate

