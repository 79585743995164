import React from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Link } from 'react-router-dom'
import AddSponsorModal from '../Sponsors/AddSponsorModal'
import AddQuizModal from '../Quizzes/AddQuizModal'


class Header extends React.Component {
  state = {}

  render () {
    return (
      <React.Fragment>
        <Navbar expand='md' className='main-nav' bg='dark' variant='dark'>
          <Container>
            <Navbar.Brand style={{ cursor: 'pointer' }} onClick={this.props.toMain}>Admin</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse>
              <Nav style={{ width: '70%' }}>
                <Link className='nav-link' to='' onClick={(event) => this.props.signOut(event)}>Sign Out</Link>
                <Link className='nav-link' to='' onClick={this.props.showPasswordModal}>Change Password</Link>
                <Link className='nav-link' to='' onClick={this.props.toGames}>Games</Link>
                <Link className='nav-link' to='' onClick={this.props.toQuizzes}>Quizzes</Link>
                <Link className='nav-link' to='' onClick={this.props.toSponsors}>Sponsors</Link>
                {/* <Link className='nav-link' to='' onClick={this.props.toReports}>Reports</Link> */}
              </Nav>
              <Nav className='d-flex justify-content-between'>
                <Link className='nav-link' to='' onClick={this.props.showAddQuizModal}>Add Quiz</Link>
                <Link className='nav-link' to='' onClick={this.props.showAddSponsorModal}>Add Sponsor</Link>
              </Nav>  
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <AddSponsorModal {...this.props}></AddSponsorModal>
        <AddQuizModal {...this.props}></AddQuizModal>
      </React.Fragment>
    )
  }
}

export default Header