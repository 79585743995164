import React from 'react'
import Table from 'react-bootstrap/Table'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import DeleteGameModal from './DeleteGameModal'
import GameClientsModal from './GameClientsModal'




class GamesTable extends React.Component {
  state = {
    deleteGameModal: false,
    selectedGameId: '',
    selectedGameName: ''
  }

  deleteGameModalClose = () => {
    this.setState({ deleteGameModal: false, selectedGameId: '', selectedGameName: '' })
  }

  deleteGameModalOpen = (event) => {
    this.setState({
      deleteGameModal: true,
      selectedGameId: event.currentTarget.dataset.id,
      selectedGameName: event.currentTarget.dataset.name,
    })
  }

  closeGameClientsModal = () => {
    this.setState({ gameClientsModal: false, selectedGameId: '', selectedGameName: ''  })
  }

  openGameClientsModal = (event) => {
    this.setState({ gameClientsModal: true,
    selectedGameId: event.currentTarget.dataset.id,
    selectedGameName: event.currentTarget.dataset.name
    })
  }

  render () {
    return (
      <div style={{ marginTop: '4rem' }}>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Clients</th>
              <th><DeleteForeverIcon></DeleteForeverIcon></th>
            </tr>
          </thead>
          <tbody>
            {this.props.theGames.map((game) => (
              <tr key={game.id}>
                <td>{game.name}</td>
                <td><EditIcon style={{ cursor: 'pointer' }} data-id={game.id} data-name={game.name} onClick={(event) => this.openGameClientsModal(event)}></EditIcon></td>
                <td><DeleteForeverIcon data-id={game.id} data-name={game.name} onClick={(event) => this.deleteGameModalOpen(event)}></DeleteForeverIcon></td>
              </tr>
            ))}
          </tbody>
      </Table>
      <DeleteGameModal getClients={this.props.getGames} {...this.state} {...this.props} deleteGameModalClose={this.deleteGameModalClose}></DeleteGameModal>
      <GameClientsModal {...this.state} {...this.props} closeGameClientsModal={this.closeGameClientsModal}></GameClientsModal>
    </div>
    )
  }
}

export default GamesTable