import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'

class DeleteSponsorModal extends React.Component {
  state={}

  deleteSponsor = () => {
    axios({
      url: apiUrl + '/delete-sponsor',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        key: this.props.selectedSponsorKey
      }
    })
    .then(() => { 
      this.props.deleteSponsorModalClose()
      this.props.msgAlert({
        heading: 'Success',
        message: 'Sponsor Deleted',
        variant: 'success'
      })
    })
    .then(() => this.props.getSponsors())
    .catch((err) => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  deleteSponsorVideo = () => {
    axios({
      url: apiUrl + '/delete-sponsor-video',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        key: this.props.selectedSponsorKey
      }
    })
    .then(() => { 
      this.props.deleteSponsorModalClose()
      this.props.msgAlert({
        heading: 'Success',
        message: 'Sponsor Deleted',
        variant: 'success'
      })
    })
    .then(() => this.props.getSponsors())
    .catch((err) => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  render () {
    return (
      <Modal show={this.props.deleteSponsorModal} onHide={this.props.deleteSponsorModalClose}>
        <Modal.Header closeButton>
          <Modal.Title><ReportProblemIcon></ReportProblemIcon>&nbsp;CONFIRM!&nbsp;<ReportProblemIcon></ReportProblemIcon></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to PERMANENTLY delete this Sponsor, {this.props.selectedSponsorName}?
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-evenly'>
          <Button variant='danger' onClick={this.deleteSponsorVideo}>Yes</Button>
          <Button variant='outline-primary' onClick={this.props.deleteSponsorModalClose}>No</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default DeleteSponsorModal