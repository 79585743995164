import React from 'react'
import axios from 'axios'
import apiUrl from '../../../apiConfig'
import { Container, ListGroup, ListGroupItem, Col, Tab, Tabs, Modal, Spinner } from 'react-bootstrap' 
import setLeaderBoard from '../HelperFunctions/setLeaderBoard'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Ordinal from 'ordinal'
import { calculateScore } from '../HelperFunctions/calculateScore'

class LeaderBoardPostGame extends React.Component {
  state = {
    leaders: [],
    nationalLeaders: [],
    quiz: '',
    loaded: false,
    key: 'local'
  }

  componentDidMount () {
    setTimeout(this.getLocalLeaders, 1000)
  }
  
  getLocalLeaders = () => {
    axios({
      url: apiUrl + `/leaderboard/local/${this.props.quiz[0].id}`,
      method: 'GET'
    })
    .then(res => this.setState({ leaders: setLeaderBoard(res.data.scores)}))
    .then(() => this.getQuiz())
    .catch(() => { 
      this.props.msgAlert({
        heading: 'Something Went Wrong',
        variant: 'danger'
      })
    }
    )
  }

  getQuiz = () => {
    axios({
      url: apiUrl + `/quiz/${this.props.quiz[0].id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`
      }
    })
    .then((res) => {
      if (res.data.quiz[0]) {
      this.setState({ quiz: res.data.quiz },
        () => this.getNationalLeaders()
        )
      } else {
        this.setState({ to404: true })
      }
    })
    .catch(() => { 
      this.props.msgAlert({
        heading: 'Something Went Wrong',
        variant: 'danger'
      })
    }
    )
  }

  getNationalLeaders = () => {
    axios({
      url: apiUrl + `/leaderboard/national/${this.props.quiz[0].usergame_id}`,
      method: 'GET'
    })
    .then(res => this.setState({ nationalLeaders: setLeaderBoard(res.data.scores)},
     () => this.setState({ loaded: true })
    ))
    .catch(() => { 
      this.props.msgAlert({
        heading: 'Something Went Wrong',
        variant: 'danger'
      })
    }
    )
  }

  convertToOrdinal = (num) => {
    return Ordinal(num)
  }

  render () {
    return (
      <React.Fragment>
        <Modal size='lg' centered show={this.props.leaderBoardModal} onHide={this.props.closeLeaderBoardModal} style={{ borderRadius: '0' }}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Container className='mt-2'>
                <div>
                    {!this.state.loaded &&
                    <div style={{ textAlign: 'center', color: 'black', marginTop: '2rem' }}><Spinner animation='border' style={{ width: '100px', height: '100px', color: 'black'  }} /></div>
                    }
                    {this.state.loaded &&
                    <div>
                    <Tabs variant='pills' activeKey={this.state.key} onSelect={(k) => this.setState({ key: k })} style={{ color: 'white' }}>
                    <Tab eventKey='local' title='Local' className='custom-tab'  style={{ color: 'white' }}>
                        <Container className='mt-3'>
                        <div className='d-flex justify-content-center' style={{ borderStyle: 'solid', borderColor: 'white', paddingTop: '2rem', paddingBottom: '2rem', backgroundColor: '#343a40' }}>
                            <EmojiEventsIcon sx={{ fontSize: 100, color: '#d2ac47' }}></EmojiEventsIcon>
                        </div>
                        <div style={{ backgroundColor: 'white', fontWeight: 'bold', maxHeight: '450px', color: 'black', overflowY: 'scroll' }}>
                            <ListGroup style={{ borderRadius: '0' }}>
                            <div id='leaderboard' style={{ paddingBottom: '2rem' }} >
                            {this.state.leaders.map((l,i) => (
                                <ListGroupItem style={{ paddingLeft: '6rem', paddingRight: '6rem' }} key={i}>
                                <div className='' style={{ fontSize: '2.2rem', textAlign: 'center', color: 'black' }}>{this.convertToOrdinal(i+1)}</div>
                                {l.map((v,j) => (
                                    <div key={v.play_id}>
                                    <ListGroupItem key={v.play_id}>
                                        <div className='row'>
                                        <Col xs={12} lg={4} style={{ display: 'flex', justifyContent: 'center' }}>Name: &nbsp;{v.first_name} {v.last_name}</Col>
                                        <Col xs={12} lg={4} style={{ display: 'flex', justifyContent: 'center' }}>score: &nbsp;{calculateScore(v.quiz_duration, v.total_correct, v.total_incorrect)}</Col>
                                        <Col xs={12} lg={4} style={{ display: 'flex', justifyContent: 'center' }}>{v.high_school ? `School:  ${v.high_school}` : ''}</Col>
                                        </div>
                                    </ListGroupItem>
                                    </div>
                                ))}
                                </ListGroupItem>
                            ))}
                            </div>
                            </ListGroup>
                        </div>
                        </Container>
                    </Tab>
                    <Tab eventKey='national' title='National' style={{ color: 'black' }}>
                        <Container className='mt-3'>
                            <div className='d-flex justify-content-center' style={{ borderStyle: 'solid', borderColor: 'white', paddingTop: '2rem', paddingBottom: '2rem', backgroundColor: '#343a40' }}>
                            <EmojiEventsIcon sx={{ fontSize: 100, color: '#d2ac47' }}></EmojiEventsIcon>
                            </div>
                            <div style={{ color: 'black', fontWeight: 'bold', maxHeight: '450px', overflowY: 'scroll' }}>
                            <ListGroup style={{ borderRadius: '0' }}>
                                <div id='leaderboard' >
                                {this.state.nationalLeaders.map((l,i) => (
                                <ListGroupItem style={{ paddingLeft: '6rem', paddingRight: '6rem' }} key={i}>
                                    <div className='' style={{ fontSize: '2.2rem', textAlign: 'center', color: 'black' }}>{this.convertToOrdinal(i+1)}</div>
                                    {l.map((v,j) => (
                                    <div key={v.play_id}>
                                        <ListGroupItem key={v.play_id}>
                                        <div className='row'>
                                            <Col xs={12} lg={3} style={{ display: 'flex', justifyContent: 'center' }}>Name: &nbsp;{v.first_name} {v.last_name}</Col>
                                            <Col xs={12} lg={3} style={{ display: 'flex', justifyContent: 'center' }}>score: &nbsp;{calculateScore(v.quiz_duration, v.total_correct, v.total_incorrect)}</Col>
                                            <Col xs={12} lg={3} style={{ display: 'flex', justifyContent: 'center' }}>{v.high_school ? `School:  ${v.high_school}` : ''}</Col>
                                            <Col xs={12} lg={3} style={{ display: 'flex', justifyContent: 'center' }}>{v.city},&nbsp;{v.state}</Col>
                                        </div>
                                        </ListGroupItem>
                                    </div>
                                    ))}
                                </ListGroupItem>
                                ))}
                                </div>
                            </ListGroup>
                            </div>
                        </Container>
                    </Tab>
                    </Tabs>
                    </div>
                    }
                </div>
            {this.state.leaders.length < 1 &&
            <div style={{ textAlign: 'center', color: 'white', marginTop: '2rem' }}>No Plays Have Been Recorded as Yet.</div>
            }
            </Container>
        </Modal>
      </React.Fragment>
    )
  }
}

export default LeaderBoardPostGame