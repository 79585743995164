import { calculateScore } from './calculateScore';

const setLeaderBoard = (quizPlays) => {
  // Map each play to include the calculated score
  const playsWithScores = quizPlays.map(play => ({
    ...play,
    score: calculateScore(play.quiz_duration, play.total_correct, play.total_incorrect),
  }));

  // Sort by score in descending order
  playsWithScores.sort((a, b) => b.score - a.score);

    // Filter to keep highest score for each registrant_id
    const highestScoresPerRegistrant = playsWithScores.reduce((acc, play) => {
      const registrantId = play.registrant_id;
      if (!acc[registrantId] || acc[registrantId].score < play.score) {
        acc[registrantId] = play; // Update with the play if it's not there or if the score is higher
      }
      return acc;
    }, {});
  
    // Convert the object back into an array
    const filteredPlaysWithScores = Object.values(highestScoresPerRegistrant);

  // Group plays by score to handle ties
  const groupedByScore = filteredPlaysWithScores.reduce((acc, play) => {
    const score = play.score;
    if (!acc[score]) {
      acc[score] = [];
    }
    acc[score].push(play);
    return acc;
  }, {});

  console.log('groupedByScore', groupedByScore);

  // Convert the object back to an array and take the top 10 scores
  const topScores = Object.values(groupedByScore).slice(0, 10);
  topScores.sort((a, b) => b[0].score - a[0].score);


  return topScores;
};

export default setLeaderBoard;
