import React from 'react'
import Table from 'react-bootstrap/Table'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import DeleteSponsorModal from './DeleteSponsorModal'
import Modal from 'react-bootstrap/Modal'




class SponsorsTable extends React.Component {
  state = {
    deleteSponsorModal: false,
    selectedSponsorKey: '',
    selectedSponsorName: '',
    showImageModal: false,
    selectedImage: '',
    selectedType: ''
  }

  deleteSponsorModalClose = () => {
    this.setState({ deleteSponsorModal: false, selectedSponsorKey: '', selectedSponsorName: '' })
  }

  deleteSponsorModalOpen = (event) => {
    this.setState({
      deleteSponsorModal: true,
      selectedSponsorKey: event.currentTarget.dataset.id,
      selectedSponsorName: event.currentTarget.dataset.name,
    })
  }

  convertLink = (regLink) => {
    if (regLink.includes('=')) {
      const split = regLink.split('=')
      const embedLink = `https://www.youtube.com/embed/${split[1]}?autoplay=1`
      return embedLink
    } else if (regLink.includes('embed')) {
      return regLink + '?autoplay=1'
    } else {
      const split = regLink.split('/')
      const embedLink = `https://www.youtube.com/embed/${split[3]}?autoplay=1`
      return embedLink
    }
  }

  render () {
    return (
      <React.Fragment>
        <div style={{ marginTop: '4rem' }}>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th><DeleteForeverIcon></DeleteForeverIcon></th>
              </tr>
            </thead>
            <tbody>
              {this.props.theSponsors.map((sponsor) => (
                <tr key={sponsor.aws_key}>
                  <td>{sponsor.name}</td>
                  <td data-link={sponsor.image_link} data-video={sponsor.video} onClick={(event) => { this.setState({ showImageModal: true, selectedImage: event.target.dataset.link, selectedType: event.target.dataset.video })}} style={{ cursor: 'pointer' }}>{sponsor.video === 0 ? 'Click to See Image' : 'Click to See Video'}</td>
                  <td><DeleteForeverIcon data-id={sponsor.aws_key} data-name={sponsor.name} onClick={(event) => this.deleteSponsorModalOpen(event)}></DeleteForeverIcon></td>
                </tr>
              ))}
            </tbody>
        </Table>
        <DeleteSponsorModal getSponsors={this.props.getSponsors} {...this.state} {...this.props} deleteSponsorModalClose={this.deleteSponsorModalClose}></DeleteSponsorModal>
      </div>
      {this.state.selectedImage &&
          <Modal show={this.state.showImageModal} onHide={() => this.setState({ showImageModal: false, selectedImage: '', selectedType: '' })}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <div className='d-flex justify-content-center'>
                {this.state.selectedType === '0' &&
                <div style={{ maxWidth: '350px' }} className='ratio ratio-4x3'>
                  <img src={this.state.selectedImage} alt='sponsor-img' />
                </div>
                }
                {this.state.selectedType === '1' &&
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" class="embed-responsive-item" src={this.convertLink(this.state.selectedImage)} allowfullscreen></iframe>
                </div>
                }
              </div>  
            </Modal.Body>
          </Modal>
      }
    </React.Fragment>
    )
  }
}

export default SponsorsTable