import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import QuestionsTemplate from './QuestionsTemplate'
import AnswersTemplate from './AnswersTemplate/AnswersTemplate'

const QuestionsAnswersTemplate = ({ question, handleOptionSelect, quiz, score, gameEnded }) => {
  return (
    <Container fluid className='g-0'>
      <Row>
        <Col>
          <QuestionsTemplate question={question} quiz={quiz} score={score} gameEnded={gameEnded} />
        </Col>
      </Row>
      <Row>
        <Col>
          <AnswersTemplate question={question} handleOptionSelect={handleOptionSelect} />
        </Col>
      </Row>
    </Container>
  )
}

export default QuestionsAnswersTemplate
