import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'

class GameClientsModal extends React.Component {
  state = {
    clients: [],
    assignedClients: [],
    availableClients: [],
    assignClientId: '',
    viewClients: false,
    loaded: false
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/users',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    // .then(res => this.setState({ users: res.data.data.Users }))
    .then(res => this.removeSuperAdmins(res.data.data.Users))
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  removeSuperAdmins = (userArray) => {
    let newArray = []
    userArray.forEach((a) => {
      if (a.Attributes[4].Value !== 'akeemogle@gmail.com') {
        newArray.push(a)
      }
    })
    this.setState({ clients: newArray, loaded: true })
  }

  getUnassignedClients = () => {
    const allClients = this.state.clients
    const assignedClients = this.state.assignedClients
    const availableClients = []
    let match = false
    allClients.forEach((c) => {
      assignedClients.forEach((v) => {
        if (c.Attributes[0].Value === v.user_cognitoId) {
          match = true
        }
      })
      if (!match) {
        availableClients.push(c)
      }
      match = false
    })
    this.setState({ availableClients: availableClients })
  }

  getAssignedClients = () => {
    this.setState({ viewClients: true })
    axios({
      url: apiUrl + '/get-gamesusers',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        gameId: this.props.selectedGameId
      }
    })
    .then(res => this.setState({ assignedClients: res.data.users },
      () => this.getUnassignedClients()
      ))
  }

  submitDeleteAssignedClients = (event) => {
    event.preventDefault()
    console.log(event.target.dataset.usergameid)
    axios({
      url: apiUrl + '/remove-combo',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        user_games_id: event.target.dataset.usergameid
      }
    })
    .then(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Client Deleted',
        message: '',
        variant: 'success'
      })
    }
    )
    .then(() => this.getAssignedClients())
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const splitOne = this.state.assignClientId.split('id')
    const splitTwo = splitOne[1].split(')')
    const splitThree = splitTwo[0].split(': ')
    const id = splitThree[1]
    axios({
      url: apiUrl + '/add-combo',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        userId: id,
        gameId: this.props.selectedGameId
      }
    })
    .then(() => {
      this.setState({ assignedClients: [], availableClients: [], assignClientId: '', viewClients: false })
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Client Added',
        message: '',
        variant: 'success'
      })
    }
    )
    .then(() => this.props.closeGameClientsModal())
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }
  

  render () {
    return (
      <Modal show={this.props.gameClientsModal} onHide={() => {this.props.closeGameClientsModal(); this.setState({ assignedClients: [], availableClients: [], assignClientId: '', viewClients: false })}}>
        <Modal.Header closeButton>
          <Modal.Title>Assign or Remove Clients for {this.props.selectedGameName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <React.Fragment>
            <div className='d-flex justify-content-center'><Button onClick={this.getAssignedClients}>View Assigned Clients</Button></div>
            {this.state.assignedClients.length >= 1 &&
              <ListGroup className="mt-2">
                {this.state.assignedClients.map((client) => (
                  <ListGroup.Item key={client.id} data-id={client.id}>
                    <div className='d-flex justify-content-between'>
                      <span>{client.first_name} {client.last_name}</span><span style={{ fontWeight: 'bold' }}>{client.company}</span>
                      <Form data-usergameid={client.id} data-clientid={client.user_cognitoId} onSubmit={this.submitDeleteAssignedClients}>
                        <div className='d-flex'>
                          <Form.Check required type="checkbox" label="Check to Delete" style={{ marginRight: '1rem' }}/>
                          <Button variant='outline-danger' type='submit' data-clientid={client.user_cognitoId} data-usergameid={client.id} style={{ textDecoration: 'underline', border: 'none', padding: '0', margin: '0', backgroundColor: 'white' }}>delete</Button>
                        </div>
                      </Form>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            }
          </React.Fragment>
        </Modal.Body>
        <Modal.Body>
          {this.state.viewClients &&
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Assign a Client</Form.Label>
              <Form.Select required name='assignClientId' onChange={this.handleChange}>
                <option></option>
                {this.state.availableClients.map((client) => (
                  <option key={client.Username} data-id={client.Username}>{client.Attributes[2].Value} {client.Attributes[3].Value} {client.Attributes[5].Value} {`(id: ${client.Username})`}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 d-flex justify-content-end">
              <Button type='submit' variant='primary'>Assign</Button>
            </Form.Group>
          </Form>
          }
        </Modal.Body>
      </Modal>
    )
  }
}

export default GameClientsModal