import React from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import apiUrl from './../../../apiConfig'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

class Unauthenticated extends React.Component {
  state = {
    email: '',
    password: '',
    toAdminPanel: false,
    forgotPassword: false,
    verification: '',
    newPassword: '',
    confirmPassword: ''
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({ error: '', password: '' })
    axios({
      url: apiUrl + '/sign-in',
      method: 'POST',
      data: {
        email: this.state.email,
        password: this.state.password
      }
    })
    .then((res) => this.props.setUser(res.data.user))
    .then(() =>
      this.props.msgAlert({
        heading: 'Sign In Success',
        message: 'You have successfully signed in',
        variant: 'success'
      }))
    .then(() => this.setState({ toAdminPanel: true }))
    // .then(() => this.setState({ toAdminPanel: false }))
    .catch((error) => {
      this.setState({ error: error.response.data.error })
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Sign in Failure',
        message: error.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  resendConfirmation = () => {
    axios({
      url: apiUrl + '/resend-link',
      method: 'POST',
      data: {
        email: this.state.email
      }
    })
    .then(() =>
      this.props.msgAlert({
        heading: 'Link Sent',
        message: '',
        variant: 'success'
      }))
    .then(() => this.setState({ error: '' }))  
    .catch((error) => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Unable to resend link',
        message: error.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

forgotPassword = () => {
  axios({
    url: apiUrl + '/forgot-password',
    method: 'POST',
    data: {
      email: this.state.email
    }
  })
  .then(() => {
  this.props.msgAlert({
    heading: 'Verification Email Sent',
    message: '',
    variant: 'success'
  })
  this.setState({ error: '', password: '', forgotPassword: true })
})
.catch((error) => {
  this.props.msgAlert({
    heading: 'Unable to send email',
    message: error.response.data.error,
    variant: 'danger'
  })
}
)
}

handleSubmitResetPW = (event) => {
  event.preventDefault()
  axios({
    url: apiUrl + '/password-reset',
    method: 'POST',
    data: {
      verification: this.state.verification,
      newPassword: this.state.newPassword,
      email: this.state.email
    }
  })
  .then(() => {
    this.props.msgAlert({
      heading: 'Success',
      message: 'Password Has Been Reset',
      variant: 'success'
    })
    this.setState({ error: '', email: '', password: '', forgotPassword: false, newPassword: '', confirmPassword: '', verification: '' })
  })
  .catch((error) => {
    this.props.msgAlert({
      heading: 'Error',
      message: error.response.data.error,
      variant: 'danger'
    })
  }
  )
}

  render () {
    if (this.state.toAdminPanel) {
      return <Navigate to='/admin' />
    }
    const match = this.state.newPassword === this.state.confirmPassword
    const populated = this.state.newPassword && this.state.confirmPassword && this.state.verification
    return (
      <React.Fragment>
        <Container className='d-flex justify-content-center' style={{ paddingTop: '10rem' }}>
          {!this.state.forgotPassword &&
          <div>
            <p className='mb-5' style={{ textAlign: 'center', fontSize: '1.5rem' }}> Welcome, please sign in to the Admin Panel</p>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control required type="email" placeholder="Enter email" name='email' onChange={this.handleChange} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control required type="password" placeholder="Password" name='password' value={this.state.password} onChange={this.handleChange} onFocus={() => this.setState({ error: '' })}/>
                {this.state.error === 'User is not confirmed.' &&
                <Form.Text>You must confirm your email address.&nbsp;&nbsp;<span onClick={this.resendConfirmation} style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }}>Click here to resend.</span></Form.Text>
                }
                {this.state.error === 'Incorrect username or password.' &&
                <Form.Text><span onClick={this.forgotPassword} style={{ textDecoration: 'underline', color: 'black', cursor: 'pointer' }}>Reset Password</span></Form.Text>
                }
              </Form.Group>
              <div className='d-flex justify-content-end'>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
          }
          {this.state.forgotPassword &&
          <div>
            <p className='mb-5' style={{ textAlign: 'center', fontSize: '1.5rem' }}> Enter in the verification code sent to your email and a new password.</p>
            <Form onSubmit={this.handleSubmitResetPW}>
              <Form.Group className="mb-3">
                <Form.Label>Verification Code</Form.Label>
                <Form.Control required type="text" placeholder="" name='verification' value={this.state.verification} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>New Password&nbsp;<span style={{ fontSize: '.7rem', fontWeight: 'bold'}}>*&nbsp;min 8 chars. 1 uppercase, 1 lowercase</span></Form.Label>
                <Form.Control required type="password" placeholder="Password" name='newPassword' value={this.state.newPassword} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password {this.state.confirmPassword && !match && <span style={{ fontSize: '.7rem', fontWeight: 'bold', color: 'red' }}>***&nbsp;Passwords Do Not Match</span>}</Form.Label>
                <Form.Control required type="password" placeholder="Password" name='confirmPassword' value={this.state.confirmPassword} onChange={this.handleChange} />
              </Form.Group>
              <div className='d-flex justify-content-end'>
              <Button disabled={!populated || this.state.confirmPassword === '' || this.state.confirmPassword !== this.state.newPassword} type='submit' variant='primary'>Submit</Button>
              </div>
            </Form>
          </div>
          }
        </Container>
      </React.Fragment>
    )
  }
}

export default Unauthenticated