import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import Row from 'react-bootstrap/Row'
import { ListGroup } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import EditQuestionsModal from './EditQuestionsModal'

class Games extends React.Component {
  state = {
    loaded: false,
    theGames: [],
    editQuestionsModal: false,
    selectedUserGameName: '',
    selectedUserGameId: ''
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/get-usersgames',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        userId: this.props.userData[0].cognitoId
      }
    })
    .then(res => this.setState({ theGames: res.data.games, loaded: true }))
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  showEditQuestionsModal = (event) => {
    this.setState({ editQuestionsModal: true, selectedUserGameId: event.target.dataset.usergameid, selectedUserGameName: event.target.dataset.gamename })
  }

  closeEditQuestionsModal = () => {
    this.setState({ editQuestionsModal: false, selectedUserGameId: '', selectedUserGameName: '' })
  }

  sendEmail = () => {
    const forHref = `mailto:${this.props.helpEmail}`
    window.open(`${forHref}`, '_self')
  }

  render () {
    return (
      <React.Fragment>
        <Container><Row style={{ fontWeight: 'bold', fontSize: '2rem', paddingTop: '3rem' }}>Assigned Games</Row></Container>
        {this.state.loaded &&
          <React.Fragment>
          {this.state.theGames.length < 1 &&
          <Row style={{ paddingTop: '3rem' }}>You currently do not have any games assigned. You can contact your admin <span onClick={this.sendEmail} style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}>here.</span></Row>
          }
          {this.state.theGames.length > 0 &&
          <Container style={{ paddingTop: '4rem', maxWidth: '600px' }}>
            <ListGroup style={{ fontSize: '1.5rem' }}>
              {this.state.theGames.map((game, i) => (
                <ListGroup.Item className='d-flex justify-content-between' key={game.game_id} data-gameid={game.game_id} data-usergameid={game.id}>{i+1}.&nbsp;{game.game_name}<span style={{ textDecoration: 'underline', cursor: 'pointer' }} data-gameid={game.game_id} data-gamename={game.game_name} data-usergameid={game.id} onClick={this.showEditQuestionsModal}>Edit Questions</span></ListGroup.Item>
              ))}
            </ListGroup>
          </Container>
          }
          {this.state.selectedUserGameId &&
          <EditQuestionsModal {...this.props} {...this.state} closeEditQuestionsModal={this.closeEditQuestionsModal}></EditQuestionsModal>
          }
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

export default Games