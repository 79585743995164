import React from 'react'
import Unauthenticated from './Unauthenticated/Unauthenticated'
import { Navigate } from 'react-router-dom'

class LandingPage extends React.Component {
  state = {
    toPanel: false
  }

  componentDidMount () {
    if (this.props.user) {
      this.setState({ toPanel: true })
    }
  }

  render () {
    if (this.state.toPanel) {
      return <Navigate to='/admin' />
    }
    return (
      <React.Fragment>
        <Unauthenticated msgAlert={this.props.msgAlert} setUser={this.props.setUser}></Unauthenticated>
      </React.Fragment>
    )
  }
}

export default LandingPage