import React from 'react'
import Container from 'react-bootstrap/Container'

class SignedInAs extends React.Component {
  state={}

  render () {
    return (
      <React.Fragment>
        <Container>
          <div style={{ width: '100%', marginTop: '1rem' }}>
            <p style={{ width: '100%', textAlign: 'right' }}> Signed In as: &nbsp;{this.props.userData[0].firstName} {this.props.userData[0].lastName} of {this.props.userData[0].company}</p>
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

export default SignedInAs