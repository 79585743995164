import React from "react";
import ShortAnswersTemplate from "./ShortAnswersTemplate";
import LongAnswersTemplate from "./LongAnswersTemplate";

const AnswersTemplate = ({ handleOptionSelect, question }) => {

  const shouldShowLongAnswers = question.options.some(option => option.option.length > 36) || question.options.length > 4 || question.options.length === 2;

  return (
    <>
      {shouldShowLongAnswers ? (
        <LongAnswersTemplate handleOptionSelect={handleOptionSelect} question={question} />
      ) : (
        <ShortAnswersTemplate handleOptionSelect={handleOptionSelect} question={question} />
      )}
    </>
  );
}

export default AnswersTemplate;