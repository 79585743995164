import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import GamesTable from './GamesTable'
import AddGameModal from './AddGameModal'
import Button from 'react-bootstrap/Button'

class Games extends React.Component {
  state = {
    theGames: []
  }

  componentDidMount () {
    axios({
      url: apiUrl + '/get-games',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    .then(res => this.setState({ theGames: res.data.games }))
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  getGames = () => {
    axios({
      url: apiUrl + '/get-games',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    .then(res => this.setState({ theGames: res.data.games }))
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  render () {
    return (
      <React.Fragment>
        <div className='d-flex'>
        {this.props.userData[0].firstName}&apos;s Games
        <Button variant='primary' style={{ marginLeft: '1rem' }} onClick={this.props.showAddGameModal}>Add Game</Button>
        </div>
        <GamesTable {...this.state} {...this.props} getGames={this.getGames}></GamesTable>
        <AddGameModal {...this.props} getGames={this.getGames}></AddGameModal>
      </React.Fragment>
    )
  }
}

export default Games