import React from 'react'
import { Container } from 'react-bootstrap'

class NothingHere extends React.Component {
  state = {}

  render () {
    return (
      <>
        <Container style={{ height: '100vh'}} className='d-flex justify-content-center align-items-center'>
          <div>
            <div style={{ fontWeight: 'bold', color: 'red', fontSize: '8rem', textAlign: 'center' }} className='quiz-title'>404</div>
            <div style={{ textAlign: 'center', fontSize: '2rem' }}>Sorry, nothing here to see</div>
          </div>
        </Container>
      </>
    )
  }
}

export default NothingHere