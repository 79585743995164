import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'


class Selections extends React.Component {
  state = {}

  render () {
    return (
      <Row style={{ marginTop: '10rem' }}>
        <Col md={6}>
          <div onClick={this.props.toClients} className='d-flex justify-content-center btn' style={{ padding: '5rem', backgroundColor: '#008080', color: 'white', marginTop: '2rem' }}>
            <span style={{ width: '100%', textAlign: 'center' }}>CLIENTS</span>
          </div>
        </Col>
        <Col md={6}>
          <div onClick={this.props.toGames} className='d-flex justify-content-center btn' style={{ padding: '5rem', backgroundColor: '#008080', color: 'white', marginTop: '2rem' }}>
            <span style={{ width: '100%', textAlign: 'center' }}>GAMES</span>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Selections