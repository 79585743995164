import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function TextEditor (props) {
  const [text, setText] = useState(props.originalText);

  const handleQuillChange = (newText, delta, source, editor) => {
    setText(newText)

    props.handleChangeText(props[`name`], newText)
 
  };

  


  const module = {
    toolbar: [
      // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link']
    ]
  }

  return <ReactQuill data-id={props[`data-id`]} name={`${props[`name`]}-text_field`} modules={module} theme="snow" value={text} onChange={handleQuillChange} />

}

export default TextEditor;