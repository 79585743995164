import React from 'react'
import { Container } from 'react-bootstrap'

const SponsorMain = (props) => {
    return (
      <Container className='mb-5'>
        {props.quiz[0].sponsor_id && !props.quiz[0].sponsor_2_id &&
          <div className='d-flex justify-content-center'>
            <a href={props.quiz[0].sponsor_web_link}><img alt='sponsor' src={props.quiz[0].sponsor_link} style={{ objectFit: 'contain', maxWidth: '300px', maxHeight: '250px' }}/></a>
          </div>
        }
        {!props.quiz[0].sponsor_id && props.quiz[0].sponsor_2_id &&
          <div className='d-flex justify-content-center mb-1'>
            <a href={props.quiz[0].sponsor_2_web_link}><img alt='sponsor' src={props.quiz[0].sponsor_2_link} style={{ objectFit: 'contain', maxWidth: '300px', maxHeight: '250px' }}/></a>
          </div>
        }
        {props.quiz[0].sponsor_id && props.quiz[0].sponsor_2_id &&
        <div className='row' style={{ paddingRight: '10rem', paddingLeft: '10rem' }}>
          <div className='col-xs-12 col-lg-6 d-flex justify-content-center align-items-center mb-1'>
          <a href={props.quiz[0].sponsor_web_link}><img alt='sponsor' src={props.quiz[0].sponsor_link} style={{ objectFit: 'contain', maxWidth: '300px', maxHeight: '250px' }}/></a>
          </div>
          <div className='col-xs-12 col-lg-6 d-flex justify-content-center align-items-center mb-1'>
          <a href={props.quiz[0].sponsor_2_web_link}><img alt='sponsor' src={props.quiz[0].sponsor_2_link} style={{ objectFit: 'contain', maxWidth: '300px', maxHeight: '250px' }}/></a>
          </div>
        </div>
        }
      </Container>
    )
}

export default SponsorMain