import React from 'react'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'
import { Row, Col, Button, Modal, Form } from 'react-bootstrap'

class AddQuizModal extends React.Component {
  state = {
    title: '',
    duration: '',
    usergameId: '',
    availableGames: [],
    loaded: false,
    fields: {
      first_name: 1,
      last_name: 0,
      high_school: 0,
      company_name: 0,
      mobile_phone: 0,
      birthday: 0,
      education: 0,
      address_1: 0,
      address_2: 0,
      city: 0,
      state: 0,
      zip: 0,
    },
    aliases: [ 'First Name', 'Last Name', 'School Name', 'Company Name', 'Mobile Phone', 'Birth Date', 'Education', 'Address', 'Address Apt/Suite #', 'City', 'State', 'Zip Code' ]
  }


  sumRegistrationValues = (fields) => {
    let total = 0;
    for (let key in fields) {
      total += fields[key];
    }
    return total;
  }
    // Handle checkbox change
    handleCheckboxChange = (field) => {
      const updatedFields = {
        ...this.state.fields,
        [field]: this.state.fields[field] ? 0 : 1
      };

      const sum = this.sumRegistrationValues(updatedFields);

      if (sum === 0) {
        this.props.msgAlert({
          heading: 'At least one field must be selected',
          message: '',
          variant: 'danger'
        })
        return
      }
      this.setState(prevState => ({
        fields: {
          ...prevState.fields,
          [field]: prevState.fields[field] ? 0 : 1
        }
      }));
    }

  componentDidMount () {
    axios({
      url: apiUrl + '/get-usersgames',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        userId: this.props.userData[0].cognitoId
      }
    })
    .then(res => this.setState({ availableGames: res.data.games, loaded: true }))
    .catch(() => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Server Error',
        message: 'Please Sign Out and Try Later',
        variant: 'danger'
      })
    }
    )
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const split1 = this.state.usergameId.split('id: ')
    const split2 = split1[1].split(')')
    const usergameId = split2[0]
    axios({
      url: apiUrl + '/new-quiz',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        cognitoId: this.props.userData[0].cognitoId,
        title: this.state.title,
        duration: this.state.duration,
        usergame_id: usergameId,
        timestamp_added: Date.parse(new Date()),
        fields: this.state.fields
      }
    })
    .then(() => { 
      this.setState({ title: '', duration: '', usergameId: '', fields: {
          first_name: 0,
          last_name: 0,
          high_school: 0,
          company_name: 0,
          mobile_phone: 0,
          birthday: 0,
          education: 0,
          address_1: 0,
          address_2: 0,
          city: 0,
          state: 0,
          zip: 0,
        }
      }) 
      this.props.msgAlert({
        heading: 'Success',
        message: 'New quiz has been added.',
        variant: 'success'
      })
    })
    .then(() => this.props.closeAddQuizModal())
    .then(() => this.props.getQuizzes())
    .catch((err) => {
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  

  render () {
    const { fields } = this.state

    return (
      <Modal show={this.props.addQuizModal} onHide={() => { this.props.closeAddQuizModal(); this.setState({ title: '', duration: '' }) }}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.loaded &&
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Quiz Title</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                name="title"
                value={this.state.title}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Associated Game</Form.Label>
              <Form.Select required name='usergameId' onChange={this.handleChange}>
                <option></option>
                {this.state.availableGames.map((game) => (
                  <option key={game.id} data-id={game.id}>{game.game_name} {`(id: ${game.id})`}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Length &#40;in seconds&#41;</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                name="duration"
                value={this.state.duration}
                onChange={this.handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Select Registration Fields</Form.Label>
              <Row className='d-flex row-cols-2'>
                {Object.keys(fields).map((field, index) => (
                  <Col className='d-flex justify-content-center' key={index}>
                    <Form.Label>
                      {this.state.aliases[index]}
                    </Form.Label>
                    &nbsp;
                    <Form.Check 
                      type="checkbox" 
                      checked={fields[field] === 1} 
                      onChange={() => this.handleCheckboxChange(field)} 
                    />
                  </Col>
                ))}
              </Row>
            </Form.Group>
            <Form.Group className="mb-3 d-flex justify-content-end">
              <Button type='submit' variant='primary'>Submit</Button>
            </Form.Group>
          </Form>
          }
        </Modal.Body>
      </Modal>
    )
  }
}

export default AddQuizModal