import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'


class Selections extends React.Component {
  state = {}

  render () {
    return (
      <React.Fragment>
        <Row style={{ marginTop: '10rem' }}>
          <Col md={6}>
            <div onClick={this.props.toGames} className='d-flex justify-content-center btn' style={{ padding: '5rem', backgroundColor: '#008080', color: 'white', marginTop: '2rem' }}>
              <span style={{ width: '100%', textAlign: 'center' }}>GAMES</span>
            </div>
          </Col>
          <Col md={6}>
            <div onClick={this.props.toQuizzes} className='d-flex justify-content-center btn' style={{ padding: '5rem', backgroundColor: '#008080', color: 'white', marginTop: '2rem' }}>
              <span style={{ width: '100%', textAlign: 'center' }}>QUIZZES</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div onClick={this.props.toSponsors} className='d-flex justify-content-center btn' style={{ padding: '5rem', backgroundColor: '#008080', color: 'white', marginTop: '2rem' }}>
              <span style={{ width: '100%', textAlign: 'center' }}>SPONSORS</span>
            </div>
          </Col>
          {/* <Col md={6}>
            <div onClick={this.props.toReports} className='d-flex justify-content-center btn' style={{ padding: '5rem', backgroundColor: '#008080', color: 'white', marginTop: '2rem' }}>
              <span style={{ width: '100%', textAlign: 'center' }}>REPORTS</span>
            </div>
          </Col> */}
        </Row>
      </React.Fragment>
    )
  }
}

export default Selections