import React from 'react'
import Table from 'react-bootstrap/Table'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import DeleteUserModal from './DeleteUserModal'
import ClientGamesModal from './ClientGamesModal'




class ClientsTable extends React.Component {
  state = {
    deleteUserModal: false,
    selectedUserId: '',
    selectedUserName: '',
    clientGamesModal: false
  }

  deleteUserModalClose = () => {
    this.setState({ deleteUserModal: false, selectedUserId: '', selectedUserName: '' })
  }

  deleteUserModalOpen = (event) => {
    this.setState({
      deleteUserModal: true,
      selectedUserId: event.currentTarget.dataset.id,
      selectedUserName: `${event.currentTarget.dataset.name} ${event.currentTarget.dataset.lname}`,
    })
  }

  closeClientGamesModal = () => {
    this.setState({ clientGamesModal: false, selectedUserId: '', selectedUserName: ''  })
  }

  openClientGamesModal = (event) => {
    this.setState({ clientGamesModal: true,
    selectedUserId: event.currentTarget.dataset.id,
    selectedUserName: `${event.currentTarget.dataset.name} ${event.currentTarget.dataset.lname}`
    })
  }

  render () {
    return (
      <div style={{ marginTop: '4rem' }}>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Company</th>
              <th>Email</th>
              <th>Games</th>
              <th><DeleteForeverIcon></DeleteForeverIcon></th>
            </tr>
          </thead>
          <tbody>
            {this.props.users.map((user) => (
              <tr key={user.Attributes[0].Value}>
                <td>{user.Attributes[2].Value} {user.Attributes[3].Value}</td>
                <td>{user.Attributes[5].Value}</td>
                <td>{user.Attributes[4].Value}</td>
                <td><EditIcon style={{ cursor: 'pointer' }} data-id={user.Username} data-name={user.Attributes[2].Value} data-lname={user.Attributes[3].Value} onClick={(event) => this.openClientGamesModal(event)}></EditIcon></td>
                <td><DeleteForeverIcon style={{ cursor: 'pointer' }} data-id={user.Username} data-name={user.Attributes[2].Value} data-lname={user.Attributes[3].Value} onClick={(event) => this.deleteUserModalOpen(event)}></DeleteForeverIcon></td>
              </tr>
            ))}
          </tbody>
      </Table>
      <DeleteUserModal getClients={this.props.getClients} {...this.state} {...this.props} deleteUserModalClose={this.deleteUserModalClose}></DeleteUserModal>
      <ClientGamesModal {...this.state} {...this.props} closeClientGamesModal={this.closeClientGamesModal}></ClientGamesModal>
    </div>
    )
  }
}

export default ClientsTable