import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Background from '../../../images/bg_dark.jpg'
import Brand from './Brand'
import QuestionsAnswersTemplate from './QuestionAnswers/QuestionsAnswersTemplate'
import OptionalText from '../SponsorContent/OptionalText'

const TriviaGameTemplate = ({ question, handleOptionSelect, quiz, score, gameEnded }) => {
  return (
    <Container fluid style={{ backgroundImage: `url(${Background})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRrepeat: 'repeat-y', width: '100%', minHeight: '100vh'}}>
      <Row>
        <Col className='d-none d-lg-block'>
          <Brand quiz={quiz} />
        </Col>
        <Col className='col-xs-12 col-lg-8 g-0'>
          <QuestionsAnswersTemplate question={question} handleOptionSelect={handleOptionSelect} quiz={quiz} score={score} gameEnded={gameEnded}/>
        </Col>
        <Col className='d-none d-lg-block'>
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-start'>
          <OptionalText quiz={quiz} />
        </Col>
      </Row>
    </Container>
  )
}

export default TriviaGameTemplate
