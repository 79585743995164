import React, { createContext, useState, useEffect, useContext } from 'react';

// Create a TimerContext
const TimerContext = createContext();

const TimerProvider = ({ children, initialTime }) => {
  const [time, setTime] = useState(initialTime); // Set the initial time to 60 seconds
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let timer;
    if (isRunning && time > 0) {
      timer = setInterval(() => {
        setTime(prevTime => prevTime - 1); // Decrement the seconds
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, time]);

  const startTimer = () => {
    setIsRunning(true);
  };

  const pauseTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    setTime(initialTime); // Reset the timer to initial time
    setIsRunning(false);
  };

  return (
    <TimerContext.Provider value={{ time, startTimer, pauseTimer, resetTimer }}>
      {children}
    </TimerContext.Provider>
  );
};

// Custom hook to use TimerContext
const useTimer = () => {
  const context = useContext(TimerContext);
  if (!context) {
    throw new Error('useTimer must be used within a TimerProvider');
  }
  return context;
};

export { TimerProvider, useTimer };