import React from 'react'
import Modal from 'react-bootstrap/Modal'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import apiUrl from '../../../../../apiConfig'

class DeleteUserModal extends React.Component {
  state={}

  deleteUser = () => {
    axios({
      url: apiUrl + '/delete-user',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.user.id} ${this.props.user.refresh} ${this.props.user.access}`
      },
      data: {
        userCognitoId: this.props.selectedUserId,
        cognitoId: this.props.userData[0].cognitoId
      }
    })
    .then(() => this.props.deleteUserModalClose())
    .then(() => this.props.getClients())
    .catch((err) => {
      // console.log(error.response.data.error)
      this.props.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }
    ) 
  }

  render () {
    return (
      <Modal show={this.props.deleteUserModal} onHide={this.props.deleteUserModalClose}>
        <Modal.Header closeButton>
          <Modal.Title><ReportProblemIcon></ReportProblemIcon>&nbsp;CONFIRM!&nbsp;<ReportProblemIcon></ReportProblemIcon></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to PERMANENTLY delete {this.props.selectedUserName}?
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-evenly'>
          <Button variant='danger' onClick={this.deleteUser}>Yes</Button>
          <Button variant='outline-primary' onClick={this.props.deleteUserModalClose}>No</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default DeleteUserModal