import React from 'react'
import Container from 'react-bootstrap/Container'
import Header from './Header/Header'
import SignedInAs from './SignedInAs'
import Selections from './Selections'
import Clients from './Clients/Clients'
import Games from './Games/Games'
import Terms from './Terms/Terms'


class SuperAdmin extends React.Component {
  state = {
    clients: false,
    games: false,
    terms: false,
    addClientModal: false,
    addGameModal: false
  }

  toGames = () => {
    this.setState({
      games: true,
      terms: false,
      clients: false
    })
  }

  toClients = () => {
    this.setState({
      games: false,
      terms: false,
      clients: true
    })
  }

  toTerms = () => {
    this.setState({
      games: false,
      clients: false,
      terms: true
    })
  }

  toMain = () => {
    this.setState({
      games: false,
      clients: false
    })
  }

  showAddClientModal = () => {
    this.setState({ addClientModal: true })
  }

  closeAddClientModal = () => {
    this.setState({ addClientModal: false })
  }
 
  showAddGameModal = () => {
    this.setState({ addGameModal: true })
  }

  closeAddGameModal = () => {
    this.setState({ addGameModal: false })
  }

  render () {
    const showSelections = !this.state.clients && !this.state.games && !this.state.terms
    const showClients = this.state.clients && !this.state.games && !this.state.terms
    const showGames = this.state.games && !this.state.clients && !this.state.terms
    const showTerms = this.state.terms && !this.state.clients && !this.state.games
    return (
      <React.Fragment>
        <Header showPasswordModal={this.props.showPasswordModal} showAddClientModal={this.showAddClientModal} closeAddClientModal={this.closeAddClientModal} showAddGameModal={this.showAddGameModal} closeAddGameModal={this.closeAddGameModal} toMain={this.toMain} toClients={this.toClients} toGames={this.toGames} toTerms={this.toTerms} signOut={this.props.signOut} {...this.props} {...this.state}></Header>
        <SignedInAs {...this.props}></SignedInAs>
        <Container>
          {showSelections &&
          <Selections  toClients={this.toClients} toGames={this.toGames} {...this.props}></Selections>
          }
          {showClients &&
          <Clients {...this.props} {...this.state} showAddClientModal={this.showAddClientModal} closeAddClientModal={this.closeAddClientModal} showAddGameModal={this.showAddGameModal} closeAddGameModal={this.closeAddGameModal}></Clients>
          }
          {showGames &&
          <Games {...this.props} {...this.state} showAddGameModal={this.showAddGameModal} closeAddGameModal={this.closeAddGameModal}></Games>
          }
          {showTerms &&
          <Terms {...this.props}></Terms>
          }
        </Container>
        <Container>

        </Container>
      </React.Fragment>
    )
  }
}

export default SuperAdmin