import React from 'react'
import Container from 'react-bootstrap/Container'
import Header from './Header/Header'
import SignedInAs from './SignedInAs'
import Selections from './Selections'
import Games from './Games/Games'
import Quizzes from './Quizzes/Quizzes'
import Sponsors from './Sponsors/Sponsors'
import Reports from './Reports/Reports'

class AdminClient extends React.Component {
  state= {
    games: false,
    quizzes: false,
    sponsors: false,
    reports: false,
    addQuizModal: false,
    addSponsorModal: false
  }

  toGames = () => {
    this.setState({
      games: true,
      quizzes: false,
      sponsors: false,
      reports: false
    })
  }

  toQuizzes = () => {
    this.setState({
      games: false,
      quizzes: true,
      sponsors: false,
      reports: false
    })
  }

  toSponsors = () => {
    this.setState({
      games: false,
      quizzes: false,
      sponsors: true,
      reports: false
    })
  }

  toReports = () => {
    this.setState({
      games: false,
      quizzes: false,
      sponsors: false,
      reports: true
    })
  }

  toMain = () => {
    this.setState({
      games: false,
      quizzes: false,
      sponsors: false,
      reports: false
    })
  }

  showAddQuizModal = () => {
    this.setState({ addQuizModal: true })
  }

  closeAddQuizModal = () => {
    this.setState({ addQuizModal: false })
  }
 
  showAddSponsorModal = () => {
    this.setState({ addSponsorModal: true })
  }

  closeAddSponsorModal = () => {
    this.setState({ addSponsorModal: false })
  }

  render () {
    const showSelections = !this.state.games && !this.state.quizzes && !this.state.sponsors && !this.state.reports
    const showGames = this.state.games && !this.state.quizzes && !this.state.sponsors && !this.state.reports
    const showQuizzes = !this.state.games && this.state.quizzes && !this.state.sponsors && !this.state.reports
    const showSponsors = !this.state.games && !this.state.quizzes && this.state.sponsors && !this.state.reports
    const showReports = !this.state.games && !this.state.quizzes && !this.state.sponsors && this.state.reports
    return (
      <React.Fragment>
        <Header showAddQuizModal={this.showAddQuizModal} closeAddQuizModal={this.closeAddQuizModal} showAddSponsorModal={this.showAddSponsorModal} closeAddSponsorModal={this.closeAddSponsorModal} showPasswordModal={this.props.showPasswordModal} toGames={this.toGames} toQuizzes={this.toQuizzes} toSponsors={this.toSponsors} toReports={this.toReports} toMain={this.toMain} {...this.props} {...this.state}></Header>
        <SignedInAs {...this.props}></SignedInAs>
        <Container>
          {showSelections &&
          <Selections toGames={this.toGames} toQuizzes={this.toQuizzes} toSponsors={this.toSponsors} toReports={this.toReports}  {...this.props}></Selections>
          }
          {showGames &&
          <Games {...this.props} helpEmail={this.props.helpEmail}></Games>
          }
          {showQuizzes &&
          <Quizzes showAddQuizModal={this.showAddQuizModal} closeAddQuizModal={this.closeAddQuizModal} {...this.props} {...this.state} helpEmail={this.props.helpEmail}></Quizzes>
          }
          {showSponsors &&
          <Sponsors showAddSponsorModal={this.showAddSponsorModal} closeAddSponsorModal={this.closeAddSponsorModal} {...this.props} {...this.state} helpEmail={this.props.helpEmail}></Sponsors>
          }
          {showReports &&
          <Reports {...this.props} helpEmail={this.props.helpEmail}></Reports>
          }
        </Container>
      </React.Fragment>
    )
  }
}

export default AdminClient