import React, { Component, Fragment } from 'react'
// import Navbar from 'react-bootstrap/Navbar'
import axios from 'axios'
import apiUrl from './apiConfig'
import { v4 as uuid } from 'uuid'
import { Route, Routes } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import AutoAlert from './components/Alert/Alert'
import Form from 'react-bootstrap/Form'
import AuthenticatedRoute from './components/AuthenticatedRoutes'
import Authenticated from './components/LandingPage/Authenticated/Authenticated'
import ReactDefault from './components/ReactDefault'
import LandingPage from './components/LandingPage/LandingPage'
// import QuizPlays from './components/QuizPlays/QuizPlays'
import LeaderBoardLocal from './components/TriviaGamePlay/LeaderBoard/LeaderBoardLocal'
import NothingHere from './components/NothingHere'
// import TriviaGameTemplate from './components/TriviaGamePlay/TriviaGameTemplate/TriviaGameTemplate'
import TriviaGamePlay from './components/TriviaGamePlay/TriviaGamePlay'


class App extends Component {
  state = {
    user: null,
    msgAlerts: [],
    mounted: false,
    helpEmail: 'joe@jmcbrands.com',
    passwordModal: false,
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  }

  // UNSAFE_componentWillMount () {
  //   if (JSON.parse(localStorage.getItem('user'))) {
  //     this.setState({ user: JSON.parse(localStorage.getItem('user'))})
  //   } else {
  //     return
  //   }
  // }

  // UNSAFE_componentWillMount () {
  //   const poolData = {
  //     UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  //     ClientId: process.env.REACT_APP_CLIENT_ID
  //   }
  //   const userPool = new CognitoUserPool(poolData) 
  //   const user = userPool.getCurrentUser()
  //   if (user) {
  //     user.getSession((err, data) => {
  //       if (err) {
  //         this.setState({ user: null, mounted: true })
  //       } else {
  //         this.setState({ user: {cognitoId: data.idToken.payload.sub, id: data.idToken.jwtToken, access: data.accessToken.jwtToken, refresh: data.refreshToken.token, firstName: data.idToken.payload.given_name, lastName: data.idToken.payload.family_name, email: data.idToken.payload.email, company: data.idToken.payload[`custom:custom:CompanyName`]}, mounted: true })
  //       }
  //     })
  //   } else {
  //     this.setState({ user: null, mounted: true })
  //   }
  // }


  componentDidMount () {
    const accessToken = JSON.parse(localStorage.getItem('accessToken'))
    const refreshToken = JSON.parse(localStorage.getItem('refreshToken'))
    const idToken = JSON.parse(localStorage.getItem('idToken'))
    const cognitoId = JSON.parse(localStorage.getItem('user'))
    axios({
      url: apiUrl + '/get-session',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken} ${refreshToken} ${accessToken}`
      },
      data: {
        cognitoId: cognitoId
      }
    })
    .then(res => this.setUser(res.data.user))
    .then(() => this.setState({ mounted: true }))
    .catch(() => this.setState({ user: null, mounted: true },
      localStorage.removeItem('user')
      ))
  }

  setUser = (user) => {
    this.setState({ user })
    localStorage.setItem('user', JSON.stringify(user.cognitoId))
    localStorage.setItem('accessToken', JSON.stringify(user.access))
    localStorage.setItem('refreshToken', JSON.stringify(user.refresh))
    localStorage.setItem('idToken', JSON.stringify(user.id))
  }

  clearUser = () => {
    this.setState({ user: null })
    localStorage.removeItem('user')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('idToken')
  }

  msgAlert = ({ heading, message, variant }) => {
    const id = uuid()
    this.setState((state) => {
      return {
        msgAlerts: [...state.msgAlerts, { heading, message, variant, id }]
      }
    })
  }

  deleteAlert = (id) => {
    this.setState((state) => {
      return { msgAlerts: state.msgAlerts.filter((msg) => msg.id !== id) }
    })
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    axios({
      url: apiUrl + '/change-password',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.state.user.id} ${this.state.user.refresh} ${this.state.user.access}`
      },
      data: {
        oldPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
        cognitoId: this.state.user.cognitoId
      }
    })
    .then(() => {
      this.msgAlert({
        heading: 'Password Successfully Changed'
      })
      this.setState({ currentPassword: '', newPassword: '', confirmPassword: '', passwordModal: false })
    })
    .catch((err) => {
      this.msgAlert({
        heading: 'Error',
        message: err.response.data.error,
        variant: 'danger'
      })
    }) 
  }

  showPasswordModal = () => {
    this.setState({ passwordModal: true })
  }

  render () {
    const match = this.state.newPassword === this.state.confirmPassword
    const populated = this.state.currentPassword && this.state.newPassword && this.state.confirmPassword
    return (
      <Fragment>
        {this.state.mounted &&
        <Fragment>
        {this.state.msgAlerts.map((msgAlert) => (
          <AutoAlert
            key={msgAlert.id}
            heading={msgAlert.heading}
            variant={msgAlert.variant}
            message={msgAlert.message}
            id={msgAlert.id}
            deleteAlert={this.deleteAlert}
          />
          ))}
          <Routes>
            <Route exact path='/' element={<LandingPage msgAlert={this.msgAlert} setUser={this.setUser} user={this.state.user} />} />
            <Route
              exact path='/admin'
              element={
                <AuthenticatedRoute user={this.state.user}>
                  <Authenticated user={this.state.user} clearUser={this.clearUser} msgAlert={this.msgAlert} helpEmail={this.state.helpEmail} showPasswordModal={this.showPasswordModal}/>
                </AuthenticatedRoute>
              }
            />
            <Route path='/quizzes/:id' element={<TriviaGamePlay clearUser={this.clearUser} msgAlert={this.msgAlert} />} />
            <Route path='/leaderboards/:id' element={<LeaderBoardLocal clearUser={this.clearUser} msgAlert={this.msgAlert} />} />
            <Route path='/default' element={<ReactDefault />} />
            {/* <Route path='/trivia' element={<TriviaGameTemplate msgAlert={this.msgAlert} />}  /> */}
            <Route path='/play/:id' element={<TriviaGamePlay msgAlert={this.msgAlert} />}  />
            <Route path='*' element={<NothingHere />} />
          </Routes>
          {this.state.user &&
          <Modal show={this.state.passwordModal} onHide={() => this.setState({ passwordModal: false, currentPassword: '', newPassword: '', confirmPassword: '' })}>
            <Modal.Header closeButton>
              <Modal.Title>Change Your Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Current Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    name="currentPassword"
                    value={this.state.currentPassword}
                    onChange={this.handleChange}
                    autoFocus
                    required
                  />
                  </Form.Group>
                  <Form.Group className="mb-3">
                  <Form.Label>New Password &nbsp;<span style={{ fontSize: '.7rem', fontWeight: 'bold'}}>*&nbsp;min 8 chars. 1 uppercase, 1 lowercase</span></Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    name="newPassword"
                    value={this.state.newPassword}
                    onChange={this.handleChange}
                    required
                  />
                  </Form.Group>
                  <Form.Group className="mb-3">
                  <Form.Label>Confirm Password {this.state.confirmPassword && !match && <span style={{ fontSize: '.7rem', fontWeight: 'bold', color: 'red' }}>***&nbsp;Passwords Do Not Match</span>}</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                    required
                  />
                  </Form.Group>
                  <Form.Group className="mb-3 d-flex justify-content-end">
                    <Button disabled={!populated || this.state.confirmPassword === '' || this.state.confirmPassword !== this.state.newPassword} type='submit' variant='primary'>Submit</Button>
                  </Form.Group>
                </Form>
        </Modal.Body>
          </Modal>
          }
        </Fragment>
        }
        {/* <Navbar fixed='bottom'><div style={{ textAlign: 'center', width: '100%', fontWeight: 'bold' }}><div style={{ backgroundColor: 'white', paddingTop: '.3rem', paddingBottom: '.3rem' }}>&#169; Quizbee.fun 2024</div></div></Navbar> */}
      </Fragment>
    )
  }
}

export default App